/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { postQuery } from "../../../../../../util/firebase-firestore";
import { connect } from "react-redux";
import { sendMessage } from "../../../../../../util/util";
import "../../../../../../../assets/css/quickSearch.css"

import EqualizerIcon from '@mui/icons-material/Equalizer';


//TODO write quicksearch unit testing
// Write
class QuickSearch extends React.Component {


  /**
   * handle the Query based on the select
   * Send the query on behalf of the user
   */
  handleQuerySelect = (event) => {
    const { uid, displayName, photoURL } = this.props.user;
    this.props.setFreezeScroll(false)
    sendMessage(
      uid,
      false,
      event.target.getAttribute("value"),
      "desktop",
      this.props.fileType,
      this.props.fileDate,
      this.props.fileNames
    )
  };
  /**
   * We have the data
   * we need to render the table based on the data
   * The table is always rendered on the params color clarity and carat
   * The data in it shows
   * Count
   * MINPRICE
   * MAXPRICE
   * MEAN
   * MEDIAN
   * MODE
   * AND
   */

  getdrillDownQuery = (newCaratConditions = [], newColorConditions = [], newClarityConditions = []) => {
    //shape: rd , carat : [1.5,1.69], color : [d ,e,f]
    // drilldown shape rd carat 1.5 1.69 color d e f
    //[1.5,1.69],[],[]
    var currConditions = JSON.parse(JSON.stringify(this.props.conditions));
    if (newCaratConditions.length > 0) {
      currConditions["carat"] = newCaratConditions
    }
    if (newColorConditions.length > 0) {
      currConditions["color"] = newColorConditions
    }
    if (newClarityConditions.length > 0) {
      currConditions["clarity"] = newClarityConditions
    }

    let query = "drilldown "
    for(let entityName in currConditions)
    {
      if( currConditions[entityName] && currConditions[entityName].length > 0)
      {
        if(entityName=="carat" && currConditions[entityName].length == 2 && currConditions[entityName][0] == currConditions[entityName][1])
        {
          currConditions[entityName] = [currConditions[entityName][0]]
        }
        query += entityName+" "+currConditions[entityName].join(" ") + " "
      }
    }
    // if (currConditions["shape"] && currConditions["shape"].length > 0) {
    //   query += "shape " + currConditions["shape"].join(" ") + " "
    // }
    // if (currConditions["carat"] && currConditions["carat"].length > 0) {
    //   if (currConditions["carat"].length == 2 && currConditions["carat"][0] == currConditions["carat"][1]) currConditions["carat"] = [currConditions["carat"][0]]
    //   query += "carat " + currConditions["carat"].join(" ") + " "
    // }
    // if (currConditions["color"] && currConditions["color"].length > 0) {
    //   query += "color " + currConditions["color"].join(" ") + " "
    // }
    // if (currConditions["clarity"] && currConditions["clarity"].length > 0) {
    //   query += "clarity " + currConditions["clarity"].join(" ") + " "
    // }
    // console.log(query);
    return query
  }




  renderDatatableLink = () => {


    if (this.props.query) {
      return <span>Click <span className="dataTableLink" value={`${this.props.query} DataTable`} onClick={this.props.handleQuerySelect}>here</span> to get Datatable</span>
    }
    return <></>
  }

  
  renderQuickSearcTable = (
    colors,
    clarity,
    caratRows,
    stats,
    discountStats,
    colorValues,
    clarityValues,
    caratValues,
    validColumns
  ) => {
    const rows = [];
    /**
     * Rounding the data for double surity
     * Else the table entry overflows and looks bad
     */
    //TODO Testing

    for (let i = 0; i < colors.length; i++) {
      let tempRows = []
      for (let j = 0; j < clarity.length; j++) {
        const row = [<td key={`clarity-${i}-${j}`}>
          <a
            href="#"
            className="text-primary fw-bold"
            value={this.getdrillDownQuery([], colorValues[i], clarityValues[j])}
            onClick={this.handleQuerySelect}
          >{clarity[j]}</a>
        </td>];

        let noneCellCount = 0
        let noneColCount = 0

        for (let k = 0; k < stats[i][j].length; k++) {
          if(validColumns[k])
          {
            if (stats[i][j][k][0] === 0) {
              row.push(<td key={`none-${i}-${j}-${k}`} className="text-primary">None</td>);
              noneCellCount++;
            } else {

              const cellQuery = this.getdrillDownQuery(caratValues[k], colorValues[i], clarityValues[j])
              const statCount = Math.round(stats[i][j][k][0])
              const statMinMaxText = stats[i][j][k][1]<=stats[i][j][k][2]?<><br/>${stats[i][j][k][1]} - ${stats[i][j][k][2]}(Avg ${stats[i][j][k][3]})</>:<></>              
              stats[i][j][k][3] = Math.round(stats[i][j][k][3]);
              stats[i][j][k][4] = Math.round(stats[i][j][k][4]);
              stats[i][j][k][5] = Math.round(stats[i][j][k][5]);
              stats[i][j][k][6] = Math.round(stats[i][j][k][6]);
              let priceSeries = stats[i][j][k][7]

              let discountStatMinMaxText =  <></>
              let discountGraphText =  <></>
              let discountSeries = null
              if(discountStats)
              {
                discountStatMinMaxText = discountStats[i][j][k][1]<=discountStats[i][j][k][2]?<><br/>{discountStats[i][j][k][1]}% - {discountStats[i][j][k][2]}% (Avg {discountStats[i][j][k][3]}%)</>:<></>
                discountSeries = discountStats[i][j][k][7]
                discountGraphText = <><b>Discount:</b>{` ${discountStats[i][j][k][1]}%-${discountStats[i][j][k][2]}%,`}<b>Avg:</b> {`${discountStats[i][j][k][3]}%`}</>
              }
              let graphText = <div className="graphText">
                <span>{cellQuery.replace("drilldown ","")}</span>
                <span><b>Price per carat:</b>{` $${stats[i][j][k][1]}-$${stats[i][j][k][2]},`}<b>Avg:</b>{` $${stats[i][j][k][3]}`}</span>
                <span>{discountGraphText}</span>
              </div>
              
              
              row.push(
                <td key={`data-${i}-${j}-${k}`}>
                  <div className="pivotCellContainer">
                    {(priceSeries && priceSeries.length>0)?(<>
                      <EqualizerIcon onClick={() => {this.props.setPriceSeries(priceSeries,discountSeries,graphText)}} className="graphButton" />
                    </>):(<></>)}
                      
                    <a
                      href="#"
                      className="text-primary"
                      value={cellQuery}
                      onClick={this.handleQuerySelect}
                    >
                      {statCount}&ensp;
                      {statMinMaxText}&ensp;
                      {discountStatMinMaxText}
                      {/* {stats[i][j][k][0]} ( ${stats[i][j][k][1]} - $
                      {stats[i][j][k][2]})<br></br> average ${stats[i][j][k][3]} */}
                      {/* ${stats[i][j][k][4]} ${stats[i][j][k][5]} ${stats[i][j][k][6]} */}
                    </a>
                  </div>
                  
                </td>
              );
            }
          }
          else noneColCount++;
          
        }
        if (noneCellCount+noneColCount < stats[i][j].length) {
          tempRows.push(row);
        }
      }
      if(tempRows.length>0)
      {
        rows.push(<tr key={`color-${i}`}>
          <td rowSpan={tempRows.length}>
            <a
              href="#"
              className="text-primary fw-bold"
              value={this.getdrillDownQuery([], colorValues[i])}
              onClick={this.handleQuerySelect}
            >{colors[i]}</a>
          </td>
          {tempRows[0]}
        </tr>)
        for(let j=1;j<tempRows.length;j++)
        {
          rows.push(<tr key={`row-${i}-${j}`}>{tempRows[j]}</tr>)
        }
      }
      
      
    }
    return rows
    /**If at the first category, print the colors
     * print the data for the quickSearch tables row by row
     */
    // return rows.map((row, index) => {
    //   if (index % clarityValues.length === 0) {
    //     return (
    //       <tr>
    //         <td rowSpan={clarityValues.length}>
    //           <a
    //             href="#"
    //             className="text-primary fw-bold"
    //             value={this.getdrillDownQuery([], colorValues[index / clarityValues.length])}
    //             onClick={this.handleQuerySelect}
    //           >{colors[index / clarityValues.length]}</a>
    //         </td>
    //         {row}
    //       </tr>
    //     );
    //   }

    //   return <tr>{row}</tr>;
    // });
  };

  getValidColumns = (caratValues, caratRows, colors, clarity, stats) => {
    let validColumns = []
    for (let k = 0; k < stats[0][0].length; k++) {
      let noneCount = 0
      for (let i = 0; i < colors.length; i++) {
        for (let j = 0; j < clarity.length; j++) {
          if (stats[i][j][k][0] === 0) {
            noneCount++;
          }
        }
      }
      validColumns.push(noneCount<colors.length*clarity.length)
    }
    return validColumns
  }

  



  render() {


    let {
      colors,
      clarity,
      caratRows,
      stats,
      discountStats,
      colorValues,
      clarityValues,
      caratValues,
    } = this.props;


    let validColumns = this.getValidColumns(caratValues, caratRows, colors, clarity, stats)
    // t t f t

    let updatedCaratRows = caratRows.filter((caratRow,ind)=>{return validColumns[ind]})
    let updatedCaratValues = caratValues.filter((caratRow,ind)=>{return validColumns[ind]})
    // console.log("colors",colors)
    // console.log("clarity",clarity)
    // console.log("stats",stats)
    // console.log("colorValues",colorValues)
    // console.log("clarityValues",clarityValues)
    // console.log("caratValues",caratValues)
    // console.log(colors, clarity, stats, colorValues, clarityValues, caratValues)
    /**
     * Rendering the column name first
     * Then calling the above function to show the table
     */
    return (
      <div className="table-responsive">
        
        <table className="table table-bordered align-middle text-center table-sm pivotTable">
          <thead>
            <tr>
              <th rowSpan="2" key={`color-row`} className="fw-bold" >Color</th>
              <th rowSpan="2" key={`clarity-row`} className="fw-bold" >Clarity</th>
              <th colSpan="4" key={`stats-row`} className="fw-bold" >Stats</th>
            </tr>
            <tr key={`carat-row`}>
              {updatedCaratRows.map((caratRow, ind) => {
                if(updatedCaratValues[ind].length==2 && updatedCaratValues[ind][0]==updatedCaratValues[ind][1])
                {
                  return (<th key={`carat-${ind}`} className="text-primary fw-bold">{caratRow}</th>)
                }
                else 
                {
                  return (<>
                    <th key={`carat-${ind}`}><a
                      href="#"
                      className="text-primary fw-bold"
                      value={this.getdrillDownQuery(updatedCaratValues[ind])}
                      onClick={this.handleQuerySelect}
                    >{caratRow}</a></th>
                  </>)
                }
                
              })}
            </tr>
          </thead>
          <tbody>
            {this.renderQuickSearcTable(
              colors,
              clarity,
              caratRows,
              stats,
              discountStats,
              colorValues,
              clarityValues,
              caratValues,
              validColumns
            )}
          </tbody>
        </table>
        {this.renderDatatableLink()}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  if (!state.user) return;

  return {
    user: state.user,
  };
};
export default connect(mapStateToProps, {})(QuickSearch);
