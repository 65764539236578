/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-redeclare */
import React from "react";
import LoadingSpinner from "../../../../../util/LoadingSpinner";
import QuickSearch from "./MessageDetailChildren/quickSearch";
import { DiamondFrame } from "./MessageDetailChildren/diamondFrame";
import { auth } from "../../../../../util/firebase-authentication";
import { postQuery } from "../../../../../util/firebase-firestore";
import { uploadToStorage } from "../../../../../util/firebase-storage";
import { jsonFileBucketPath, viewerUrl } from "../../../../../../config/projectconfigs";
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SearchResult } from "./MessageDetailChildren/SearchResult";
import { FileAck } from "./MessageDetailChildren/FileAck";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faFileExport, faFlag, faPen } from "@fortawesome/free-solid-svg-icons";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import "./MessageDetail.css"
import Tooltip from '@mui/material/Tooltip';
// import LoadingDots from "@react-native-loading-dots";
import BouncingDotsLoader from "../../../../../util/BouncingDotsLoader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import TextField from '@mui/material/TextField';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // console.error("Error in child of messageDetail:", error);
  }

  render() {
    if (this.state.hasError) {
      const photoUrl = "/images/messageError.png"
      const name = "message from system"
      let isError = this.props.queryMode === "error" ? "errorMessage" : ""

      return (
        <div id={this.props.docID} className="messageDiv item py-4 px-2 d-flex position-relative" >
          < img alt="photoimg" className="ui avatar messagePhoto image inline" src={photoUrl} />
          <div className="position-relative content w-100 overflow-auto">
            <div className="header p-1">
              <span className={`${isError} messageText`} >Failed to Load Message</span>
              <small className="messageSmall text-muted small p-1 ">
                <span>{name}</span>
              </small>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default class MessageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statFormOpen: false,
      us: false,
      nonUs: false,
      isLoading: this.props.botReply ? true : false, // If the message is from bot, then show the loading spinner 
      additionalInfo: "",
      screenshot: null,
      screenshotError: null,
      reportDialogOpen: false, // Initialize reportDialogOpen state
    }
  }

  componentDidMount() {
    // until the bot reply is not loaded, show the loading spinner for the bot reply
    if (this.props.botReply) {
      setTimeout(() => {
        this.setState({ isLoading: false })
      }, 1000)
    }
  }


  changeStatFormStatus = (newStatus) => {
    this.setState({ statFormOpen: newStatus })
  }

  handleQuerySelect = (event, query = null, docId = null) => {
    let text;
    if (query) text = query
    else text = event.target.getAttribute("value")
    let queryDoc = {
      botReply: false,
      text: text,
      uid: auth.currentUser.uid,
      timeStamp: new Date().valueOf(),
      device: "desktop",
      fileDate: this.props.fileDate,
      fileNames: this.props.fileNames
    }
    if (!queryDoc.fileDate) {
      delete queryDoc.fileDate
    }
    if (!queryDoc.fileNames) {
      delete queryDoc.fileNames
    }
    if (docId) {
      queryDoc["docId"] = docId
    }
    postQuery(`chats/${auth.currentUser.uid}/${this.props.fileType}`, queryDoc);

  };
  
  pasteQuery = (event) => {
    this.props.appendQuery(event, true)
  }

  renderLoader = () => {
    return <div><BouncingDotsLoader /></div>
  }

  submitStatForm = (e) => {
    let docID = this.props.docID
    let target = "market "
    let query = `stats ${target} ${this.state.us ? "us" : ""} ${this.state.nonUs ? "non-us" : ""}`
    this.handleQuerySelect(null, query, docID)
    this.setState({ statFormOpen: false, us: false, nonUS: false })
  }

  showReportQuery = () => {
    this.setState({ reportDialogOpen: true });
  };

  openReportDialog = () => {
    this.setState({ reportDialogOpen: true });
  };

  closeReportDialog = () => {
    this.setState({ reportDialogOpen: false });
  };

  sendEmailToUser = async (data) => {
  try {
    const response = await fetch(
      "https://us-central1-d360-assist-dev.cloudfunctions.net/sendEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            email: auth.currentUser.email,
            subject: "Report Submitted Successfully",
            html: `<p>Dear ${auth.currentUser.displayName},</p>
                   <p>Thank you for your report. Here is the additional information you provided:</p>
                   <pre>Information: ${data["Additional Info"]}</pre>
                   <p>We will review it shortly.</p>
                   <p>Best Regards,<br/>D360 Tech.</p>`,
          },
        }),
      }
    );

  } catch (error) {
    console.error("Error sending email:", error);
  }
};

  sendEmailToCompany = async (data) => {
  try {
    const response = await fetch(
      "https://us-central1-d360-assist-dev.cloudfunctions.net/sendEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            email: "info@d360.tech",
            subject: "New Report Submitted by User - " + auth.currentUser.email,
            html: `<p>A new report has been submitted by ${auth.currentUser.displayName} (${auth.currentUser.email}). Here are the details:</p>
                   <pre>User Account Name: ${data["User Account Name"]}</pre>`
              + (data["File type"] ? `<pre>File type: ${data["File type"]}</pre>` : "")
              + (data["Message time"] ? `<pre>Message time: ${data["Message time"]}</pre>` : "")
              + (data["botReply"] ? `<pre>Bot Reply: ${data["botReply"]}</pre>` : "")
              + (data["Query"] ? `<pre>Query: ${data["Query"]}</pre>` : "") 
              + (data["Bot Response"] ? `<pre>Bot Response: ${data["Bot Response"]}</pre>` : "")
              + (data["Additional Info"] ? `<pre>Additional Info: ${data["Additional Info"]}</pre>` : "")
              + (data["Device"] ? `<pre>Device: ${data["Device"]}</pre>` : "")
              + (data["File Date"] ? `<pre>File Date: ${data["File Date"]}</pre>` : "")
              + (data["File Names"] ? `<pre>File Names: ${data["File Names"]}</pre>` : "")
              + (data["Report Time"] ? `<pre>Report Time: ${data["Report Time"]}</pre>` : "")
              + (data["Reported by"] ? `<pre>Reported by: ${data["Reported by"]}</pre>` : "")
            ,

            
          },
        }),
      }
    );
  } catch (error) {
    console.error("Error sending email:", error);
  }
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxFileSize = 5 * 1024 * 1024; // 5MB

    if (file) {
      if (!validFileTypes.includes(file.type)) {
        this.setState({ screenshotError: "Invalid file type. Only JPEG, JPG, and PNG are allowed.", screenshotFile: null});
        return;
      }
      if (file.size > maxFileSize) {
        this.setState({ screenshotError: "File size exceeds 5MB.", screenshotFile: null});
        return;
      }
      this.setState({ screenshotFile: file, screenshotError: "" });
    }
  };

  // submitReportQuery = async () => {
  //   const { screenshotFile } = this.state;
  //   let screenshotUrl = "";

  //   if (screenshotFile) {
  //     console.log(`Submitting screenshot: ${screenshotFile.name}`);
  //     const storageRef = storage.ref();
  //     const screenshotRef = storageRef.child(`screenshots/${auth.currentUser.uid}/${Date.now()}_${screenshotFile.name}`);
  //     await screenshotRef.put(screenshotFile);
  //     screenshotUrl = await screenshotRef.getDownloadURL();
  //   }

  //   let reportDoc = {
  //     "User Account Name": auth.currentUser.displayName,
  //     "File type": this.props.fileType,
  //     "Message time": new Date(this.props.timeStamp).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }),
  //     "botReply": this.props.text || 'N/A',
  //     "Query": this.props.query || "N/A",
  //     "Bot Response": this.props.botReply,
  //     "Additional Info": this.state.additionalInfo,
  //     "Device": "Desktop",
  //     "File Date": this.props.fileDate || "N/A",
  //     "File Names": this.props.fileNames || "N/A",
  //     "Report Time": new Date().toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }),
  //     "Reported by": auth.currentUser.email || "N/A",
  //     "Screenshot URL": screenshotUrl,
  //   };

  //   // await postQuery(`reportQuery/${auth.currentUser.uid}/${this.props.fileType}`, reportDoc);

  //   this.sendEmailToUser(reportDoc);
  //   this.sendEmailToCompany(reportDoc);
  //   this.closeReportDialog();
  // };

  
  handleAdditionalInfoChange = (event) => {
    this.setState({ additionalInfo: event.target.value });
  };


  renderChild = () => {
    //console.log(this.props)
    const { ...allProps } = this.props;
    // console.log(allProps);
    // console.log(this.props.fileType);
    // console.log(this.props.text);
    // console.log(this.props.fileDate);
    // console.log(this.props.fileNames);

    switch (this.props.queryMode) {
      /**
       * Basically checking if the result type is a table
       * Then dont visit all the rows where it is marked as hidden
       * Then push the data in a array where we remove the hidden columns
       */
      case "searchResult":
        return (
          <SearchResult
            columnPreference={this.props.columnPreference}
            handleQuerySelect={this.handleQuerySelect}
            {...allProps}
          />
        );
      case "quick-search":
        const quickSearchparsed = JSON.parse(this.props.quickSearch);
        // let tempqsParsed =  '[["color", "clarity", "carat"], ["d e f", "g h", "i j", "k l m n"], ["if", "vvs", "vs", "si", "i"], [["1.5 to 1.5"]], [["d", "e", "f"], ["g", "h"], ["i", "j"], ["k", "l", "m", "n"]], [["fl", "if"], ["vvs1", "vvs2"], ["vs1", "vs2"], ["si1", "si2"], ["i1", "i3"]], [[1.5, 1.5]]]'
        // const quickSearchparsed = JSON.parse(tempqsParsed);
        const statsparsed = JSON.parse(this.props.stats);
        let discountStatsParsed = null
        if (this.props.discountStats) {
          discountStatsParsed = JSON.parse(this.props.discountStats);
        }

        // let tempStats = '[[[[0, 1000000, 0, 0, 0, 0, 0]], [[0, 1000000, 0, 0, 0, 0, 0]], [[40, 4935, 15882, 5977.5, 5876.5, 5674.5, 975.25]], [[37, 3137, 10664, 3490.17, 3413.5, 3260.17, 279.94]], [[0, 1000000, 0, 0, 0, 0, 0]]], [[[0, 1000000, 0, 0, 0, 0, 0]], [[0, 1000000, 0, 0, 0, 0, 0]], [[44, 4885, 10491, 5294.5, 5237.5, 5123.5, 361.37]], [[81, 2367, 9202, 2838.0, 2776.5, 2653.5, 381.75]], [[0, 1000000, 0, 0, 0, 0, 0]]], [[[0, 1000000, 0, 0, 0, 0, 0]], [[0, 1000000, 0, 0, 0, 0, 0]], [[36, 3114, 8783, 3336.5, 3381.5, 3471.5, 137.67]], [[66, 2341, 7215, 2610.75, 2547.5, 2421.0, 255.39]], [[0, 1000000, 0, 0, 0, 0, 0]]], [[[0, 1000000, 0, 0, 0, 0, 0]], [[0, 1000000, 0, 0, 0, 0, 0]], [[0, 1000000, 0, 0, 0, 0, 0]], [[0, 1000000, 0, 0, 0, 0, 0]], [[0, 1000000, 0, 0, 0, 0, 0]]]]'
        // const statsparsed = JSON.parse(tempStats);
        const fileDate = (this.props.fileDate) ? (this.props.fileDate) : null
        const fileNames = (this.props.fileNames) ? (this.props.fileNames) : []


        /**
         * This is where the quick search mode is being run
         */

        return (
          <QuickSearch
            colors={quickSearchparsed[1]}
            clarity={quickSearchparsed[2]}
            caratRows={quickSearchparsed[3]}
            colorValues={quickSearchparsed[4]}
            clarityValues={quickSearchparsed[5]}
            caratValues={quickSearchparsed[6]}
            stats={statsparsed}
            discountStats={discountStatsParsed}
            fileType={this.props.fileType}
            fileDate={fileDate}
            fileNames={fileNames}
            query={this.props.query}
            conditions={this.props.conditions}
            handleQuerySelect={this.handleQuerySelect}
            setPriceSeries={this.props.setPriceSeries}
            setFreezeScroll={this.props.setFreezeScroll}
          />
        );
      /**
       * The src link will be showed here
       */
      case "srcLink":
        // console.log(this.props.srclink);
        return <DiamondFrame srclink={this.props.srclink} />;
      /**
       * The pdf link will be showed here
       */
      case "pdfLink":
        // console.log(this.props.srclink);
        return <DiamondFrame srclink={this.props.pdflink} />;
      /**
       * When a fileAcknowledgement is there, show it using here
       */
      case "fileAck":
        return <FileAck {...allProps} />;
      /**
       * These are all the modes
       * If it falls in none of it,it means that it is a text message
       * So if text is there return nothing
       * because text is rendered in the css components
       * The loading spinner is when the text is null
       */
      default:
        switch (this.props.text) {
          case null:
            return <LoadingSpinner />;
          default:
            return <></>;
        }
    }
  };

  checkResponse = (e) => {
    let descText = this.props.finalPath
    if (this.props.description) descText = this.props.description
    this.props.setResponse(this.props.docID, `Editing Response for : ${descText}`)
  }

  exportJson = (e) => {
    let docID = this.props.docID
    let jsonObj = JSON.parse(JSON.stringify(this.props))
    // console.log(jsonObj["timeStamp"]);
    jsonObj["timeStamp"] = Date.now()
    // console.log(jsonObj["timeStamp"]);

    const jsonString = JSON.stringify(jsonObj);
    const jsonFile = new Blob([jsonString], { type: 'application/octet-stream' });
    let filePath = jsonFileBucketPath + docID + ".json/"
    uploadToStorage(filePath, jsonFile).then((snp) => {
      // console.log(snp);
      // let url = viewerUrl+docID
      // let url = "https://storage.googleapis.com/kp-assist/viewer/index.html?res_id=" + docID;
      let url = viewerUrl.replace("{resId}", docID)
      var newTab = window.open(url, "_blank");
      newTab.focus();
    })
  }

  renderChatMessage = () => {
    try {
      const botReply = this.props.botReply;
      const { isLoading } = this.state;
      let messageTime = new Date(this.props.timeStamp);
      messageTime = `${messageTime.toLocaleTimeString().slice(0, -3)} - ${messageTime.toLocaleDateString()}`;
      const name = this.props.botReply ? "message from system" : (auth.currentUser ? auth.currentUser.displayName : "User");
      let text = this.props.text;
      let isError = this.props.queryMode === "error" ? "errorMessage" : "";
      let extraText = [];

      if (isError && this.props.query) {
        extraText.push(<span className="text-primary fw-bold editQueryLink" value={this.props.query} onClick={this.pasteQuery}>EDIT QUERY</span>);
      }

      return (
        <>
          <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
            <div className="position-relative content w-100 overflow-auto">
                <>
                  {(this.props.queryMode === "searchResult") ? (
                    <>
                      <div className="accessbilityBtns d-flex position-absolute">
                        {isLoading && botReply ? <Skeleton circle={true} height={20} width={20} /> : (
                          this.props.docId && (
                            <Tooltip arrow title="Scroll To Source Message">
                              <div className="threadBtn" onClick={(e) => { document.getElementById(this.props.docId).scrollIntoView({ block: 'start', behavior: 'smooth' }) }}>
                                <svg height="1em" viewBox="0 0 384 512"><path fill="currentColor" d="M350 177.5c3.8-8.8 2-19-4.6-26l-136-144C204.9 2.7 198.6 0 192 0s-12.9 2.7-17.4 7.5l-136 144c-6.6 7-8.4 17.2-4.6 26s12.5 14.5 22 14.5h88l0 192c0 17.7-14.3 32-32 32H32c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32l80 0c70.7 0 128-57.3 128-128l0-192h88c9.6 0 18.2-5.7 22-14.5z" /></svg>
                              </div>
                            </Tooltip>
                          )
                        )}
                        <Tooltip arrow title="Search for given diamond from other source">
                          {isLoading && botReply? <Skeleton circle={true} height={20} width={20} /> : (
                            <div className="showStatForm">
                              <ManageSearchIcon onClick={(e) => { this.changeStatFormStatus(true) }} />
                              <Dialog className="statFormContainer" onClose={(e) => { this.changeStatFormStatus(false) }} open={this.state.statFormOpen}>
                                <IconButton
                                  aria-label="close"
                                  onClick={(e) => { this.changeStatFormStatus(false) }}
                                  sx={{
                                    position: 'absolute',
                                    right: 4,
                                    top: 4,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                                <div className="statForm">
                                  <h2>Add Market Data</h2>
                                  <FormGroup>
                                    <div className="formFields">
                                      <FormControlLabel control={<Checkbox onChange={(e) => { this.setState({ us: e.target.checked }) }} />} label="US Market" />
                                      <FormControlLabel control={<Checkbox onChange={(e) => { this.setState({ nonUs: e.target.checked }) }} />} label="NON-US Market" />
                                    </div>
                                    <Button disabled={!(this.state.us || this.state.nonUs)} onClick={this.submitStatForm} type="submit" variant="contained">
                                      Submit
                                    </Button>
                                  </FormGroup>
                                </div>
                              </Dialog>
                            </div>
                          )}
                        </Tooltip>
                        <Tooltip arrow title="Export Data to Sharable Link">
                          {isLoading && botReply ? <Skeleton circle={true} height={20} width={20} /> : (
                            <div onClick={this.exportJson} className="exportJson">
                              <FontAwesomeIcon icon={faFileExport} />
                            </div>
                          )}
                        </Tooltip>
                        {isLoading && botReply ? <Skeleton circle={true} height={20} width={20} /> : (
                          this.props.checked ? (
                            <Tooltip arrow title="This Response is selected as target for edit query">
                              <div onClick={this.checkResponse} className="selectResponse">
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip arrow title="Select current response for executing edit query">
                              <div onClick={this.checkResponse} className="selectResponse">
                                <svg height="1em" viewBox="0 0 512 512"><path fill="currentColor" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                              </div>
                            </Tooltip>
                          )
                        )}
                      </div>
                    </>
                  ) : (<></>)}
                  <div className="header p-1">
                    {this.props.queryMode === "loader" ? (
                      this.renderLoader()
                    ) : (
                      <>
                        {this.props.queryMode === "help"
                          ? isLoading && botReply
                            ? <Skeleton count={3} />
                            : text.split("\\n").map((phrase, index) => (
                                <span className="messageText" key={index}>
                                  <pre>{phrase}</pre>
                                </span>
                              ))
                          : isLoading && botReply
                          ? <Skeleton count={2} height={20} width={900} />
                          : text.split("\\n").map((phrase, index) => (
                              <span className={`${isError} messageText`} key={index}>
                                {phrase}
                                {isError.length ? <>&ensp;</> : <br />}
                              </span>
                            )).concat(extraText.map((extra, index) => (
                              <span key={`extra-${index}`}>
                                {extra}
                              </span>
                            )))}
                        <div className="messageData header p-1">{this.renderChild()}</div>
                      </>
                    )}
                    <div>
                      <small className="messageSmall text-muted small p-1 ">
                        <span>{name}</span>
                        <span>{messageTime}</span>
                      </small>
                    </div>
                  </div>
                </>
            </div>
            {!this.props.botReply && (
              <Tooltip title="Edit Query">
                <div className="messageEditButtonDiv" value={text} onClick={this.pasteQuery}>
                  {<FontAwesomeIcon className="messageEditButton" icon={faPen} />}
                </div>
              </Tooltip>
            )}          
            
            {this.props.botReply && (
              <Tooltip title="Report Issue">
                <div className="messageEditButtonDiv" onClick={this.showReportQuery} >
                      <FontAwesomeIcon icon={faFlag} className="messageEditButton"/>
                </div>
              </Tooltip>
            )}
          </SkeletonTheme>
          

        </>
      );
    } catch (error) {
      console.log(error);
    }
  }


render() {
    const photoUrl = this.props.botReply ? "/images/logo.png" : auth.currentUser.photoURL;

    return (
      <ErrorBoundary>
        <div id={this.props.docID} className="messageDiv item py-4 px-2 d-flex position-relative">
          <img className="ui avatar messagePhoto image inline" src={photoUrl} alt="User" />
          {this.renderChatMessage()}
          <Dialog open={this.state.reportDialogOpen} onClose={this.closeReportDialog}>
            <div style={{ padding: "20px", position: "relative" }}>
              <IconButton style={{ position: "absolute", right: "10px", top: "10px" }} onClick={this.closeReportDialog}>
                <CloseIcon />
              </IconButton>
              <h2>Report Issue</h2>
              <TextField
                label="Additional Information"
                fullWidth
                multiline
                rows={4}
                value={this.state.additionalInfo}
                onChange={this.handleAdditionalInfoChange}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
                onClick={async () => {
                  let reportDoc = {
                    "User Account Name": auth.currentUser.displayName,
                    "File type": this.props.fileType,
                    "Message time": new Date(this.props.timeStamp).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }),
                    "botReply": this.props.text || 'N/A',
                    "Query": this.props.query || "N/A",
                    "Bot Response": this.props.botReply,
                    "Additional Info": this.state.additionalInfo,
                    "Device": "Desktop",
                    "File Date": this.props.fileDate || "N/A",
                    "File Names": this.props.fileNames || "N/A",
                    "Report Time": new Date().toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }),
                    "Reported by": auth.currentUser.email || "N/A"
                  };

                  // Using the below code you will be able to post the report to the firestore database. 
                  // await postQuery(`reportQuery/${auth.currentUser.uid}/${this.props.fileType}`, reportDoc);
                  
                  this.sendEmailToUser(reportDoc);
                  this.sendEmailToCompany(reportDoc);
                  this.closeReportDialog();
                }}
              >
                Submit Issue
              </Button>
            </div>
          </Dialog>
        </div>
      </ErrorBoundary>
    );
}
};