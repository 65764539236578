import React from "react";
import { MDBDataTableV5 } from "mdbreact";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { colors } from "@mui/material";
import "../../../../../../../assets/css/ButtonCss.css";

export default class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hiddenColumns: [],
      originalData: {}, 
      tableId: `table-${Math.random().toString(36).substr(2, 9)}` // Unique table ID
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        loading: false,
        originalData: { ...this.props.data } // copy of original data 
      });
    }, 1300); 
  }

  handleHeaderClick = (index) => {
    this.setState((prevState) => {
      const hiddenColumns = [...prevState.hiddenColumns];
      const columnIndex = hiddenColumns.indexOf(index);
      if (columnIndex === -1) {
        hiddenColumns.push(index);
      } else {
        hiddenColumns.splice(columnIndex, 1);
      }
      return { hiddenColumns };
    });
  };

  // function to reset table to original data
  handleReset = () => { 
    this.setState({
      hiddenColumns: [],
      originalData: { ...this.props.data }
    });
  };

  modify(data) {
    const { hiddenColumns } = this.state;

    let cols = data.columns.map((x) => x.label);
    let rows = data.rows;
    let linkHeaders = {
      cert: "certificate",
      lab: "certificate",
      vid: "view",
      view: "view",
    };

    for (let col of cols) {
      col = String(col); // Ensure col is a string because it may be an object which shows up as [object Object] and causes an error
      for (let linkHeader in linkHeaders) {
        if (col.toLowerCase().includes(linkHeader)) {
          linkHeaders[col] = linkHeaders[linkHeader];
          break;
        }
      }
    }
    
    let newdata = rows.map((row) => {
      let reportNumber = row["Report no"];
      if ("Generated report no" in row) reportNumber = row["Generated report no"];
      for (let col of cols) {
        col = String(col); 
        let val = row[col];
        if (col.toLowerCase().includes("report")) {
          row[col] = (
            <span
              className="reportVal"
              title="Paste to Clipboard"
              value={`${row[col]}`}
              onClick={this.props.appendQuery}
            >
              {row[col]}
            </span>
          );
        } else if (Number.isNaN(val)) {
          row[col] = <span>-</span>;
        } else if (typeof val === "string") {
          if (val.toLowerCase() === "nan") {
            row[col] = <span>-</span>;
          } else if (val.startsWith("http")) {
            let linkLabel = col.split(" ")[0];
            if (linkHeaders[col]) {
              row[col] = (
                <span
                  className="text-primary cursor-pointer"
                  value={`${linkHeaders[col]} ${reportNumber}`}
                  onClick={this.props.handleQuerySelect}
                >
                  {linkLabel}
                </span>
              );
            } else {
              row[col] = (
                <a className="text-primary" href={val} target="_blank" rel="noopener noreferrer">
                  {linkLabel}
                </a>
              );
            }
          } else {
            row[col] = val.toUpperCase();
          }
        }
      }
      return row;
    });

    return data;
  }

  render() {
    const { loading, hiddenColumns, tableId} = this.state;
    const { data } = this.props;
    const showResetButton = hiddenColumns.length > 0;

    if (loading) {
      return (
        <SkeletonTheme color="#e0e0e0" highlightColor="#f0f0f0">
          <div style={{ width: "100%", height: "500px" }}>
            <Skeleton count={10} height={40} />
          </div>
        </SkeletonTheme>
      );
    }

    let modifiedData = this.modify(data);
    modifiedData.columns = data.columns.map((col, index) => {
      col.sortIcon = "right";
      if (typeof col.label === 'string' && !col.label.includes('-')) {
        col.label = (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <span style={{ fontWeight: "bold", fontFamily: "Inter" }}>{col.label}</span> 
            <a className="remove-col-btn"
              href="#"
              onClick={() => this.handleHeaderClick(index)}
              style={{
                display: "inline-block",
                width: "fit-content",
                height: "fit-content",
                marginLeft: "8px",
                borderRadius: "100%",
                textAlign: "center",
                backgroundColor: hiddenColumns.includes(index) ? "#f0f0f0" : "#e0e0e0",
              }}
              title="Hide column"
            >
              <i className="fas fa-times"></i>
            </a>
          </div>
        );
      }
      return col;
    });


    return (
    <div className={`data-table-container ${tableId}`} style={{ display: "flex", flexDirection: "column"}}>
        {showResetButton && (  // Conditionally render reset button container
          <div className="reset-btn-container" style={{ display: "flex", justifyContent: "flex-end"}}>
            <a className="reset-btn" onClick={this.handleReset} style={{ fontWeight: "lighter", fontFamily: "Inter", letterSpacing: "0.3px" }}>
              <i className="reset-icon fas fa-undo"></i><span className="reset-txt">Reset</span>
            </a>
          </div>
        )}

        <div className="table-container">
      <MDBDataTableV5
        style={{
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
          maxHeight: "500px",
          hover: true,
          color: colors.grey[900],
          fontSize: "12px",
          fontWeight: "bold",
          fontFamily: "Arial",
          textAlign: "center",
          border: "1px solid black",
          borderRadius: "5px",
          padding: "5px",
          margin: "5px",
          backgroundColor: colors.grey[50],
        }}
        small
        searchTop
        bordered
        striped
        responsive
        searchBottom={false}
        data={modifiedData}
        paginationLabel={["<", ">"]}
        entriesOptions={[5, 10, 20, 50, 100]}
        entries={5}
        pagesAmount={4}
        infoLabel={["Showing", "to", "of", "entries"]}
      />
    </div>

        <style jsx="true">{`
          .${tableId} {
            width: 100%;
          }
          ${hiddenColumns.map(index => `
            .${tableId} table tr th:nth-child(${index + 1}),
            .${tableId} table tr td:nth-child(${index + 1}) {
              display: none;
            }
          `).join('')}
          @media (max-width: 1000px) {
            .${tableId} .sorting::before, .${tableId} .sorting::after,
            .${tableId} .sorting_asc::before, .${tableId} .sorting_asc::after,
            .${tableId} .sorting_desc::before, .${tableId} .sorting_desc::after {
              display: none !important;
            }
          }
        `}</style>
      </div>
    );
  }
}
