import React from "react";
import "../../../../assets/css/Tutorial.css";
import DefaultUserImage from "../../../../assets/images/profile_placeholder.png";

import companyImage from "../../../../assets/images/Tutorial/LOGO.png"
import M1 from "../../../../assets/images/Tutorial/M1.png"
import M2 from "../../../../assets/images/Tutorial/M2.png"
import M3 from "../../../../assets/images/Tutorial/M3.png"
import M4 from "../../../../assets/images/Tutorial/M4.png"
import M5 from "../../../../assets/images/Tutorial/M5.png"
import M6 from "../../../../assets/images/Tutorial/M6.png"
import M7 from "../../../../assets/images/Tutorial/M7.png"
import M8 from "../../../../assets/images/Tutorial/M8.png"
import M9 from "../../../../assets/images/Tutorial/M9.png"
import M10 from "../../../../assets/images/Tutorial/M10.png"
import M11 from "../../../../assets/images/Tutorial/M11.png"
import M12 from "../../../../assets/images/Tutorial/M12.png"
import M13 from "../../../../assets/images/Tutorial/M13.png"


import T1 from "../../../../assets/images/Tutorial/T1.png"
import T2 from "../../../../assets/images/Tutorial/T2.png"
import T3 from "../../../../assets/images/Tutorial/T3.png"
import T4 from "../../../../assets/images/Tutorial/T4.png"
import T5 from "../../../../assets/images/Tutorial/T5.png"
import T6 from "../../../../assets/images/Tutorial/T6.png"
import T7 from "../../../../assets/images/Tutorial/T7.png"
import T8 from "../../../../assets/images/Tutorial/T8.png"
import T9 from "../../../../assets/images/Tutorial/T9.png"
import T10 from "../../../../assets/images/Tutorial/T10.png"
import T11 from "../../../../assets/images/Tutorial/T11.png"


class Tutorial extends React.Component {

  tutPageContentAll = [{},
  {
    "title": "Welcome to Diamond Assist",
    "subTitle": "This short tutorial will walk you through all of the features of this application.",
    "text": <p>If you want to dive right in, feel free to press the 'Skip Tutorial' button below. Otherwise, press 'Next'!</p>,
    "imageUrl": [companyImage],
    "pageAllowed": ["default"]
  }, {
    "title": "Messenger",
    "subTitle": null,
    "text": <><p>You can select file from which you want to get Information,And if you don't have any.. you can easily upload you files in User or Vendor Section</p>
      <p>Without uploading user or vendor files, you want be able to execute query over Data</p>
      <p>You can refer following links to check procedure of uploading <a style={{ color: "dodgerblue" }} onClick={(e) => { this.setState({ currPage: 6 }) }}>User</a> / <a style={{ color: "dodgerblue" }} onClick={(e) => { this.setState({ currPage: 7 }) }}>Vendor</a> Files.</p></>
    ,
    "imageUrl": [M1, M2],
    "pageAllowed": ["default", "home"]
  },
  {
    "title": "Query Generation",
    "subTitle": "You can provide your query either in text or use 'Easy Query' to generate your own customized queries",
    "text": null,
    "imageUrl": [M3],
    "pageAllowed": ["default", "home"]
  },
  {
    "title": "Types of Queries",
    "subTitle": null,
    "text": <div className="typesOfQuery">
      <p>You can input search string by specifying values of 4Cs of diamond: you are looking for seperated by space.On top of that you can also add different values for fluorescence,shape, price in USD, one or more GIA report number.</p>
      <p><b>Some of the example queries are:</b></p>
      <p>"rd 6k xxx E F 1.0"</p>
      <p>"si1 vs1 0.98 1.05 D H 3x none rd"</p>
      <p>"Round Princess xxx si 1/2 I medium 3000 4000"</p>
      <p>"I2 pk 75 pointer D H 3vg medium 2.7k"</p>
      <p>Note: Each of the Diamond result shown in the result,will have parameter either equal or better than what you asked for in search.If you looking for exact search, you can add "exact" keyword to search query.</p>
      <p className="typesOfQueryExamples">Example: "exact vvs1 g h rd"</p>
      <p><b>The other modes supported are:</b></p>
      <p>certificate, view, dd(Drilldown), email, pair, qs(quick search), Hide, Show, Rate, Image, Video, </p>
      <p>To know about all of the above modes, simply type "help MODE_NAME" in the chatbox</p>
    </div>,
    "imageUrl": null,
    "pageAllowed": ["default", "home"]
  }
    ,
  {
    "title": "Response",
    "subTitle": null,
    "text": <>
      <p>1. On provided response each column can be sorted individually and pagination can be customised</p>
      <p>2. And for each Search Result there are some accessebility functions given</p>
      <p>(<b>Search in Datatable, Scroll to Unchanged Data,Open response in new sharable page, Edit Response</b>)</p>
    </>
    ,
    "imageUrl": [M4],
    "pageAllowed": ["default", "home"]
  },
  {
    "title": "Upload User Files",
    "subTitle": null,
    "text": <>
      <p>1. First Select Date for which you are uploading Files</p>
      <p>2. And then you can upload more than one csv / xls / xlsx files.</p>
      <p>3. You will be notified once all files are uploaded successfully and if there is some issue in some file.</p>
    </>
    ,
    "imageUrl": [M5, M6],
    "pageAllowed": ["default", "upload files"]
  },
  {
    "title": "Upload Vendor Files",
    "subTitle": null,
    "text": <>
      <p>1. First Select Date for which you are uploading Files</p>
      <p>2. You can also add new Vendor names.</p>
      <p>3. while uploading file you need to select specific vendor for that file.</p>
      <p>4. For Each vendor Multiple files can be uploaded.</p>
    </>
    ,
    "imageUrl": [M7, M8],
    "pageAllowed": ["default", "upload files"]
  },
  {
    "title": "Merge Files",
    "subTitle": null,
    "text": <>
      <p>1. Date must be selected for which you want to merge uploaded files.</p>
      <p>2. This function is available in both types(user/vendor) of files.</p>
      <p>3. From action buttons you can mail generated file by providing recipient's mail id.</p>
      <p>4. And same file can be downloaded to local device.</p>
      <p>4. You can easily export merged file to Messenger for performing any query.</p>
    </>
    ,
    "imageUrl": [M9],
    "pageAllowed": ["default", "upload files"]
  },
  {
    "title": "Export Merged Files",
    "subTitle": null,
    "text": <>
      <p>1. Once Files are merged, they are available to access directly.</p>
      <p>2. In below dropdown, Dates of past few available merge files will be given.</p>
      <p>3. You can Download or Mail any of them.</p>
      <p>4. And each file can be used for Messenger to perform query with export functionality.</p>
    </>
    ,
    "imageUrl": [M10, M11],
    "pageAllowed": ["default", "upload files"]
  },
  {
    "title": "File Analysis",
    "subTitle": null,
    "text": <>
      <p>1. It allows you to make analysis among previously merged files.</p>
      <p>2. You need to first select two dates in given dropdowns for which files were merged.</p>
      <p>3. And then you can create user or vendor analysis file containing stats like count,max-price, min-price, etc.</p>
      <p>4. Mail and Download functions are also available for each generated file.</p>
    </>
    ,
    "imageUrl": [M12, M13],
    "pageAllowed": ["default", "analysis"]
  }
  ]

  tutPageContentAll = [{},
  {
    "title": "Welcome to Diamond Assist",
    "subTitle": "This short tutorial will walk you through all of the features of this application.",
    "text": <p>If you want to dive right in, feel free to press the 'Skip Tutorial' button below. Otherwise, press 'Next'!</p>,
    "imageUrl": [companyImage],
    "pageAllowed": ["default"]
  }, {
    "title": "Messenger",
    "subTitle": null,
    "text": <><p><b>Specify File Type: </b> From the available options, select the file type that corresponds to the file you have uploaded.</p>
      <p><b>Choose a Date: </b>Select the date on which you uploaded the file.</p>
      <p><b>Select Your File: </b>From the list of files uploaded on the chosen date, select the specific file(s) you would like to work with. You must select at least one file.</p></>
    ,
    "imageUrl": [T1, T2, T3],
    "pageAllowed": ["default", "home"]
  },
  {
    "title": "Query Generation",
    "subTitle": null,
    "text": <>
      <p>Our chatbot offers two ways to provide your query: </p>
      <p><b>Text Field : </b>If you are comfortable with the query format, you can directly type your query into the provided text field.</p>
      <p><b>Easy Query Form : </b>Need help building your query? Use the "Easy Query" form. The chatbot will generate a text query based on your selections. You can edit this query before submitting.</p>
    </>,
    "imageUrl": [T4],
    "pageAllowed": ["default", "home"]
  },
  {
    "title": "Types of Queries",
    "subTitle": null,
    "text": <div className="typesOfQuery">
      <p><b>search:</b> Retrieve diamond data in a table format. Filter your results by specifying characteristics such as shape, clarity, carat range, price, cut grade, color, and fluorescence.</p>
      <p className="typesOfQueryExamples">Example: em vvs+ 1.5ct-2.5ct 1000$ to 2000$, 3ex d-f, medium DEF</p>

      <p><b>drilldown:</b> Analyze data visually and explore specific sections. Start with a general visualization and progressively "drill down" into areas of interest by applying filters.</p>
      <p className="typesOfQueryExamples">Example: drilldown, dd rd 1.5, dd vvs 3vg</p>

      <p><b>certificate:</b> Fetch the detailed certificate report for a specific diamond using its report number. </p>
      <p className="typesOfQueryExamples">Example: certificate &#x007B;ReportNumber&#x007D;</p>

    </div>,
    "imageUrl": null,
    "pageAllowed": ["default", "home"]
  },
  {
    "title": "Types of Queries",
    "subTitle": null,
    "text": <div className="typesOfQuery">
      <p><b>view:</b>  Load a 3D visual representation of a diamond, allowing you to examine it from different angles. Use the report number to view a specific diamond.</p>
      <p className="typesOfQueryExamples">Example: view &#x007B;ReportNumber&#x007D;</p>

      <p><b>compare:</b> Analyze the characteristics of multiple diamonds side-by-side.  Specify the report numbers of the diamonds you want to compare.</p>
      <p className="typesOfQueryExamples">Example: compare &#x007B;ReportNumber1&#x007D; &#x007B;ReportNumber2&#x007D; ...</p>

      <p><b>pair:</b> Find diamonds that closely match the characteristics of a given diamond, or discover pairs of diamonds with very similar attributes. Provide the report number of the reference diamond.</p>
      <p className="typesOfQueryExamples">Example: pair &#x007B;ReportNumber&#x007D; </p>
    </div>,
    "imageUrl": null,
    "pageAllowed": ["default", "home"]
  },
  {
    "title": "Types of Queries",
    "subTitle": null,
    "text": <div className="typesOfQuery">
      <p><b>Rate Change:</b> Adjust the price values of diamonds in your data. Apply changes by percentage or by a fixed amount. </p>
      <p className="typesOfQueryExamples">Example: rate 5%, rate 200, rate -7%, rate -100</p>

      <p><b>email:</b> Share the chatbot's responses with others via email. Send to your default email address or specify a different recipient.</p>
      <p className="typesOfQueryExamples">Example: mail (default address), email &#x007B;MailId&#x007D; </p>

      <p><b>view change:</b> Customize the displayed data by hiding or showing specific columns in the response table.</p>
      <p className="typesOfQueryExamples">Example: hide cut clarity, show all, show total</p>


    </div>,
    "imageUrl": null,
    "pageAllowed": ["default", "home"]
  },
  {
    "title": "Types of Queries",
    "subTitle": null,
    "text": <div className="typesOfQuery">


      <p><b>alias:</b> Create shortcuts (aliases) for frequently used, complex queries. </p>
      <p className="typesOfQueryExamples">Example: rd1.5vg alias round shape 1.5 carat very good cut</p>

      <p><b>clear:</b> Remove previous chat history for a fresh start.</p>
      <p className="typesOfQueryExamples">Example: cls, clear</p>

      <p><b>help:</b> Get instructions and guidance on using any of the chatbot's features.</p>
      <p className="typesOfQueryExamples">Example: help drilldown, help compare</p>
    </div>,
    "imageUrl": null,
    "pageAllowed": ["default", "home"]
  }
    ,
  {
    "title": "Additional Features",
    "subTitle": null,
    "text": <>
      <p><b>1.Flexible Data Control</b></p>
      <p>&emsp;<b>Column Sorting & Custom Pagination: </b>Sort each column in the response table individually and customize</p>
      <p>&emsp;the number of results displayed per page.</p>
      <p><b>2. Enhanced Search Result Accessibility</b></p>
      <p>&emsp;<b>Search within Results: </b>Find specific data within the results table and easy navigation to the desired data.</p>
      <p>&emsp;<b>Shareable Results & Editable Response: </b>Open results in a separate page for sharing and modify results.</p>
      <p>&emsp;<b>Report Bug or Query: </b>Report any bug or query to the admin for further assistance.</p>
    </>
    ,
    "imageUrl": [T11],
    "pageAllowed": ["default", "home"]
  },
  {
    "title": "Upload Files",
    "subTitle": null,
    "text": <>
      <p><b>Specify File Type:</b> Indicate the type of file you are uploading (e.g., Inventory/Supplier File).</p>
      <p><b>Choose Upload Date:</b> Select the date on which you would like the file(s) to be saved.</p>
      <p><b>Select Files:</b> Choose one or more files <b>(xlsx/xls/csv)</b> from your local device to upload, and click "Upload Files" to initiate the upload process.</p>
    </>
    ,
    "imageUrl": [T6, T7, T8],
    "pageAllowed": ["default", "upload files"]
  },
  {
    "title": "Files Monitoring",
    "subTitle": null,
    "text": <>
      <p><b>Upload Monitoring:</b> Track the current progress of your file uploads in real-time.</p>
      <p><b>Validation Feedback:</b> Receive notifications indicating whether your uploaded files are valid or have errors.</p>
      <p><b>Recent Files:</b> Successfully uploaded files will automatically appear in the "Recent Files" section for quick access.</p>
    </>
    ,
    "imageUrl": [T9, T10],
    "pageAllowed": ["default", "upload files"]
  }]
  state = {
    currPage: 1,
    pageType: sessionStorage.getItem("pageType")
  }

  tutPageContent = [{},
  ...this.tutPageContentAll.filter(tutPage => {
    return tutPage.pageAllowed && tutPage.pageAllowed.includes(this.state.pageType.toLowerCase())
  })]


  nextPage = () => {
    let currPage = this.state.currPage
    if (currPage < this.tutPageContent.length - 1) {
      this.setState({ currPage: currPage + 1 })
    }
  }
  prevPage = () => {
    let currPage = this.state.currPage
    if (currPage > 1) {
      this.setState({ currPage: currPage - 1 })
    }
  }

  changeToPage = (e) => {
    // console.log(e.target);
    let toPage = parseInt(e.target.toPage)
    // console.log(toPage);
  }

  renderTutPage = (ind) => {
  let currContent = this.tutPageContent[ind];
  return (
    <>
      {currContent.title ? <h3>{currContent.title}</h3> : <></>}
      {currContent.subTitle ? <h6>{currContent.subTitle}</h6> : <></>}
      <div id="tutorialText">
        {currContent.text ? <>{currContent.text}</> : <></>}
      </div>
      <div id="tutorialCounter">{ind}/{this.tutPageContent.length - 1}</div>
      <div id="mainTutorialImageDiv">
        {currContent.imageUrl ? (
          currContent.imageUrl.map((image, index) => {
            let imgName = image.split(".")[0].split("/").slice(-1);
            return <img key={index} alt="Tutorial Image" id={imgName} className="mainTutorialImage" src={image} />;
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );
};


  finishTut = () => {
    sessionStorage.setItem("showTut", "false")
    this.props.setShowTut(false);
    this.setState({ currPage: 1 })
  }

  getShowTut = () => {
    let showTut = true

    if (sessionStorage.getItem("showTut") === null) sessionStorage.setItem("showTut", "true")
    else showTut = (sessionStorage.getItem("showTut") === 'true')
    return showTut

  }

  render = () => {
    if (!this.getShowTut()) return (<></>)
    return (
      <>
        <div id="tutorialBackGround">
          <div id="tutorial">
            {this.renderTutPage(this.state.currPage)}
            <div id="controlButtons">
              <button onClick={this.finishTut} id="skipButton" className="btn btn-default navbar-btn" type="button">Skip Tutorial</button>
              <span></span>
              <button onClick={this.prevPage} disabled={(this.state.currPage === 1)} id="previousButton" className="btn btn-default navbar-btn" type="button">Previous</button>
              {(this.state.currPage === this.tutPageContent.length - 1) ? (

                <button onClick={this.finishTut} id="finishButton" className="btn btn-default navbar-btn" type="button">Finish</button>
              ) : (
                <button onClick={this.nextPage} id="nextButton" className="btn btn-default navbar-btn" type="button">Next</button>
              )}
            </div>

          </div>
        </div>

      </>
    );

  }
}

export default Tutorial;