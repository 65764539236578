import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';

class FileInput extends React.Component {

    onFileSelect = (event) => {

        this.props.onFileSelect(event.target.id, event.target.files);
    }

    render() {
        return (
            <div className="input-group">
                <Tooltip arrow title="Upload File from local device">
                <input
                    id={this.props.id}
                    key={this.props.id}
                    className="form-control"
                    type="file"
                    multiple
                    onChange={this.onFileSelect}

                />
                </Tooltip>
                <Tooltip arrow title="Remove current File Slot">

                    <span className="input-group-text bg-danger">
                        <a onClick={() => this.props.removeFileInput(this.props.id, this.props.index)}>
                            <FontAwesomeIcon icon={faMinusCircle} className="text-white" />
                        </a>
                    </span>
                </Tooltip>
            </div>
        )
    }
}

export default FileInput;