import React from "react";
import "../../../../../../src/assets/css/TextBox.css";

/**
 * Similarly for the TextBox we have created a new component
 * This gives the reusability of a component and many other things
 * Also gives us the option to render dynamically
 */
export class TextBox extends React.Component {
  render() {
    return (
        /**
         * Uses the text-area class for css property.
         * This archive the code reisability when other text box added
         */
        <input className={`text-area ${(this.props.className)?(this.props.className):''}`} 
        type = 'text'
        placeholder={this.props.placeholder}
        value={this.props.queryText}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        onChange={this.props.onInputChange}
        onKeyDown={this.props.handleKeyDown}
        key={this.props.flag}
        disabled={!this.props.isAllowed}
        align="middle"
        id="queryTextInput"
      ></input>
    );
  }
}
