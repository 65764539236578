import React from "react";
/**
 * This is used when we are trying to process an unack file
 * It works when the file is shown for download
 * This is showing the file like icon and asking to download
 */
export class FileAck extends React.Component {
  render() {
    return (
      <span>
        Click{" "}
        <a href={this.props.downloadURL} target="_blank" rel="noreferrer">
          here
        </a>
        . to download.
      </span>
    );
  }
}
