import React from 'react';
import { faFileExcel, faThList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { addFileMetaData, postQuery } from '../../../../util/firebase-firestore';
import { uploadToStorage, listBucketObjects, getFromStorage } from '../../../../util/firebase-storage';
import FileInput from './FileInput';
import MergedFile from "./MergedFile"
// import xlsx from 'xlsx';
import * as XLSX from 'xlsx/xlsx.mjs';
import './analysis.css';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { getDatesApi, makeAnalysisApi, summaryBucket, vendorConvertApi } from "../../../../../config/projectconfigs"


import { firebaseConfig } from '../../../../../config/firebase';
import createNotification from '../../../../util/Notification';




class Analysis extends React.Component {
    state = {
        userID: this.props.userID,
        userDateList: [],
        vendorDateList: [],
        userDate: null,
        vendorDate: null,
        userAnalysisFile: null,
        vendorAnalysisFile: null,
        isUserAnalysing: false,
        isVendorAnalysing: false
    }

    addNotification(notfObj) {
        const curr_notf = this.props.notfList
        this.props.setNotfList([...curr_notf, notfObj])
    }



    renderAvailableDates = (userId) => {

        const payload_user = { "user_id": userId, "isUser": "True","projectId": firebaseConfig["projectId"] }
        const payload_vendor = { "user_id": userId, "isUser": "False" ,"projectId": firebaseConfig["projectId"] }
        try {
            axios.defaults.withCredentials = false
            axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
            axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

            axios.get(getDatesApi, { params: payload_user }, {
                crossDomain: true
            }).then(response => {
                let dateList = response.data.files.map((date, index) => {
                    return (
                        <option value={date} key={index}>{date}</option>
                    )
                });

                this.setState({ userDateList: [<option disabled selected value=""> --- Select a Date --- </option>, ...dateList, (dateList.length === 0) ? <option disabled>No Dates available</option> : null] });

            })
                .catch(error => {
                    // console.log("Error Fetching Dates for user :", error);
                    this.setState({ userDateList: [<option disabled selected value=""> --- Select a Date --- </option>, <option disabled>No Dates available</option>] });

                });
        } catch (error) {
            // console.log("Error Fetching Dates for user :", error);
            this.setState({ userDateList: [<option disabled selected value=""> --- Select a Date --- </option>, <option disabled>No Dates available</option>] });
        }

        try {


            axios.get(getDatesApi, { params: payload_vendor }, {
                crossDomain: true
            }).then(response => {
                let dateList = response.data.files.map((date, index) => {
                    return (
                        <option value={date} key={index}>{date}</option>
                    )
                });

                this.setState({ vendorDateList: [<option disabled selected value=""> --- Select a Date --- </option>, ...dateList, (dateList.length === 0) ? <option disabled>No Dates available</option> : null] });

            })
                .catch(error => {
                    // console.log("Error Fetching Dates for vendor :", error);
                    this.setState({ vendorDateList: [<option disabled selected value=""> --- Select a Date --- </option>, <option disabled>No Dates available</option>] });

                });
        } catch (error) {
            // console.log("Error Fetching Dates for vendor :", error);
            this.setState({ vendorDateList: [<option disabled selected value=""> --- Select a Date --- </option>, <option disabled>No Dates available</option>] });


        }
    }

    makeAnalysis = (e) => {
        let analysisType = e.target.name
        let flag
        if (analysisType == "User") {this.setState({ isUserAnalysing: true });flag="1"}
        else if (analysisType == "Vendor"){ this.setState({ isVendorAnalysing: true });flag="2"}
        let userDate = this.state.userDate
        let vendorDate = this.state.vendorDate
        if (!userDate) {
            createNotification(`Please select date for User file first`, 'warning')
            this.setState({ isAnalysing: false })
            return;
        }
        if (!vendorDate) {
            createNotification(`Please select date for Vendor file first`, 'warning')
            this.setState({ isAnalysing: false })
            return;

        }



        userDate = this.state.userDate.replaceAll(/[/ \-]/g, "")
        vendorDate = this.state.vendorDate.replaceAll(/[/ \-]/g, "")
        const payload = { "userId": this.props.userID, "userDate": userDate, "vendorDate": vendorDate,"flag":flag,"projectId": firebaseConfig["projectId"] }

        try {
            axios.get(makeAnalysisApi, { params: payload }, {
                crossDomain: true
            }).then(response => {
                if (response.status == "200") {

                    const BASE_DIR = `${summaryBucket}/${this.props.userID}/AnalysisFiles/${userDate}${vendorDate}/`;
                    Promise.resolve(getFromStorage(BASE_DIR, `Master_${analysisType}_file_with_count.csv`))
                        .then((analysisFileURL) => {
                            this.addNotification(createNotification(`Analysis completed Successfully`, 'success', `Analysis completed  successfully for  User:${this.state.userDate} & Vendor:${this.state.vendorDate}`))
                            if(this.state[analysisType.toLowerCase()+"AnalysisFile"])
                            {
                                createNotification(`Analyis File for ${analysisType} Updated Successfully`, 'success')
                            }
                            
                            if (analysisType == "User") {
                                this.setState({
                                    userAnalysisFile: {
                                        CREATEDAT: (new Date()).toISOString().substr(0, 10),
                                        downloadURL: analysisFileURL,
                                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                        filePath: '',
                                        fileName: "UserAnalysisFile"
                                    },
                                    isUserAnalysing: false
                                })
                            }
                            else if (analysisType == "Vendor") {
                                this.setState({
                                    vendorAnalysisFile: {
                                        CREATEDAT: (new Date()).toISOString().substr(0, 10),
                                        downloadURL: analysisFileURL,
                                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                        filePath: '',
                                        fileName: "VendorAnalysisFile"
                                    },
                                    isVendorAnalysing: false
                                })
                            }
                            // this.setState({
                            //     outputFiles: [{
                            //         CREATEDAT: (new Date()).toISOString().substr(0, 10),
                            //         downloadURL: await userAnalysisFile.getDownloadURL(),
                            //         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            //         filePath: '',
                            //         fileName: "UserAnalysisFile"
                            //     }, {
                            //         CREATEDAT: (new Date()).toISOString().substr(0, 10),
                            //         downloadURL: await vendorAnalysisFile.getDownloadURL(),
                            //         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            //         filePath: '',
                            //         fileName: "VendorAnalysisFile"
                            //     }],
                            //     isAnalysing: false
                            // })

                        })
                        .catch(() => {
                            this.addNotification(createNotification(`Error While Fetching Analysis File for ${analysisType}`, 'error'));
                            if (analysisType == "User") this.setState({ isUserAnalysing: false })
                            else if (analysisType == "Vendor") this.setState({ isVendorAnalysing: false })
                        })
                }
                else {
                    if (analysisType == "User") this.setState({ isUserAnalysing: false })
                    else if (analysisType == "Vendor") this.setState({ isVendorAnalysing: false })
                    this.addNotification(createNotification(`Error Occured while Making Analysis`, 'error', `Error in making analysis for User:${this.state.userDate} & Vendor:${this.state.vendorDate}`))
                }

            })
                .catch(error => {
                    // console.log("Error while making Analysis", error);
                    if (analysisType == "User") this.setState({ isUserAnalysing: false })
                    else if (analysisType == "Vendor") this.setState({ isVendorAnalysing: false })
                    this.addNotification(createNotification(`Error Occured while Making Analysis`, 'error', `Error in making analysis for User:${this.state.userDate} & Vendor:${this.state.vendorDate}`))

                });
        } catch (error) {
            // console.log("Error while making Analysis", error);
            if (analysisType == "User") this.setState({ isUserAnalysing: false })
            else if (analysisType == "Vendor") this.setState({ isVendorAnalysing: false })
            this.addNotification(createNotification(`Error Occured while Making Analysis`, 'error', `Error in making analysis for User:${this.state.userDate} & Vendor:${this.state.vendorDate}`))


        }
    }


    handleSubmit(event) {
        event.preventDefault();
    }

    componentDidMount = () => {
        this.renderAvailableDates(this.props.userID)
    }

    render() {
        this.props.setShowTut(false)
        let outputFiles = []
        if(this.state.userAnalysisFile)outputFiles.push(this.state.userAnalysisFile)
        if(this.state.vendorAnalysisFile)outputFiles.push(this.state.vendorAnalysisFile)
        // console.log(this.props);
        return (
            <div className="analysisContainer container">


                <div className='analysisContainerElement'>
                    <form onSubmit={this.handleSubmit}>

                        <div className="dateSelectContainer form-group text-center">
                            <div className="form-group mt-3 mb-2">
                                <label>Select Date to For User File</label>
                                <Tooltip arrow title="Select Date representing merged User file">
                                <select
                                    className='form-select w-50 mx-auto'
                                    value={this.state.userDate}
                                    onChange={(e) => this.setState({ userDate: e.target.value })}
                                    required
                                >
                                    {this.state.userDateList.map(date => {
                                        return date
                                    })}
                                </select>
                                </Tooltip>
                            </div>
                            <div className="form-group mt-3 mb-2">
                                <label>Select Date to For Vendor File</label>
                                <Tooltip arrow title="Select Date representing merged Vendor file">

                                <select
                                    className='form-select w-50 mx-auto'
                                    value={this.state.vendorDate}
                                    onChange={(e) => this.setState({ vendorDate: e.target.value })}
                                    required
                                >
                                    {this.state.vendorDateList.map(date => {
                                        return date
                                    })}
                                </select>
                                </Tooltip>

                            </div>
                        </div>
                        <div className='d-flex w-50 mx-auto gap-2'>
                        <Tooltip arrow title="You can create Analysis File For user having count, min-price and max-price for each data.">
                            <button name='User' onClick={this.makeAnalysis} className="analysisBtn btn btn-success" disabled={this.state.isUserAnalysing}>
                                {(this.state.isUserAnalysing) ? (<>
                                    <CircularProgress title='Analyzing' className='analysisLoading' color="success" />
                                </>) : (<>
                                    Get User Analysis File
                                </>)}

                            </button>
                            </Tooltip>
                        <Tooltip arrow title="You can create Analysis File For Vendor having count, min-price and max-price for each data.">

                            <button name='Vendor' onClick={this.makeAnalysis} className="analysisBtn btn btn-success" disabled={this.state.isVendorAnalysing}>
                                {(this.state.isVendorAnalysing) ? (<>
                                    <CircularProgress title='Analyzing' className='analysisLoading' color="success" />
                                </>) : (<>
                                    Get Vendor Analysis File
                                </>)}

                            </button>
                            </Tooltip>

                        </div>


                    </form>

                    {outputFiles.length? (
                        <>
                            <div className="outputFiles text-center">
                                <MergedFile data={outputFiles} header={`Output of Analysis`} ></MergedFile>
                            </div>
                        </>

                    ) : (<></>)}
                </div>


            </div>
        )
    }
}

const mapStateToProps = state => {
    if (!state.user) return {};

    return {
        userID: state.user.uid
    }
}

export default connect(mapStateToProps, {})(Analysis);