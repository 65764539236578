
/**
 * Takes the data from the response
 * Applies the hidden columns and gives the result
 */
export const conditionalDataCreator = (
  searchResult,
  hiddenIndices,
  columns
) => {
  let vis = [];
  for (let i = 0; i < columns.length; i++) vis[i] = 1;
  if (hiddenIndices != null)
    for (let i = 0; i < hiddenIndices.length; i++) vis[hiddenIndices[i]] = 0;
  let conditionalData = [];

  for (let i in searchResult) {
    let crow = [];
    for (let j = 0; j < searchResult[i].length; j++) {
      if (vis[j] === 1) crow.push(searchResult[i][j]);
    }
    conditionalData.push(crow);
  }
  return conditionalData;
};
/**
 * Returns the dataColumns with a label according to the standard doc
 */
export const getColumns = (columns,columnSpecs={}) => {
  const dataColumns = columns.map((label) => {
    label = label.replaceAll(/[#|_|*|-|\s+]/g," ").trim()
    label = label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()
    let field = label
    if(label in columnSpecs)label = columnSpecs[label]
    return {
      label: label,
      field: field,
      width: 50,
    };
  });
  return dataColumns;
};
/**
 * get the data according to the standard doc
 */
export const getDataRows = (conditionalData,columns) => {
  const dataRows = conditionalData.slice(1).map((row) => {
    const tuple = {};

    row.forEach((value, index) => {
      if(typeof value==="string")
      {
        if(!value.startsWith("http"))
        {
          value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
        }
      }
      else if(typeof value==="number")
      {
        if(!Number.isInteger(value))
        {
          value = value.toFixed(2)
        }
      }
      tuple[columns[index]] = value
    });
    return tuple;
  });
  return dataRows;
};
