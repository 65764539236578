

// import { firestore } from 'firebase-admin';
// import { initializeApp } from 'firebase-admin/app';
// import { getStorage } from 'firebase-admin/storage';
// import { getDatabase } from 'firebase-admin/database';
// import { getFunctions } from 'firebase-admin/functions';
// import { getFirestore } from 'firebase-admin/firestore';

import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getDatabase } from 'firebase/database';


export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}


export const app = initializeApp(firebaseConfig);


export const domain = process.env.REACT_APP_DOMAIN


export const realtimeDB = getDatabase()

export const firestoreDB = getFirestore()

export const storage = getStorage()

export const functions = getFunctions()
