import {postQuery } from "./firebase-firestore"
import { auth } from "./firebase-authentication.js";
// import React from "react";
// import { firestoreDB } from "../../config/firebase.js";



export const sendMessage = (uid,botReply,text,device,fileType,fileDate=null,fileNames=[],resId=null) => {

    let postQueryCallback = () => {
        let postJson ={
            botReply: botReply,
            text: text,
            timeStamp: new Date().valueOf(),
            device: device,
            uid : auth.currentUser.uid
        }
        if(resId)postJson["docId"] = resId
        if(fileDate)postJson["fileDate"] = fileDate.replaceAll(/[/ \-]/g, "")
        if(fileNames.length)postJson["fileNames"]= fileNames
        postQuery(`chats/${uid}/${fileType}`,postJson);
    }

    postQueryCallback()
    
}
