/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { handleGoogleSignIn } from "../util/firebase-authentication";
import Navigation from "../App/AppChildren/AppContainerChildren/Navigation";
import Tutorial from "../App/AppChildren/AppContainerChildren/Tutorial";
import "../../assets/css/SignIn.css";
import { app } from "../../config/firebase";
export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTut: false,
    };
  }

  appInfo = [
    {
      title: "Effortless Diamond Searches",
      data: (
        <>
          <ul>
            <li>Search for diamonds using a variety of criteria, including shape, carat weight, color, clarity, and more.</li>
            <li>Find the perfect diamond for your needs with advanced search filters and sorting options.</li>
            <li>Download your search results in a variety of formats for easy sharing and analysis.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Interactive Results & Pivot Tables",
      data: (
        <>
          <ul>
            <li>View your search results in an interactive table with detailed information on each diamond.</li>
            <li>Use pivot tables to analyze your data and uncover insights that can help you make informed decisions.</li>
            <li>Sort your results by price, carat weight, color, clarity, and other criteria to find the best diamonds for your needs.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Easy Query Creation & Customization",
      data: (
        <>
          <ul>
            <li>Create custom queries to find diamonds that meet your specific criteria.</li>
            <li>Saving your queries for easy access and reuse.</li>
            <li>Customize your queries using Easy Query to quickly find the diamonds you're looking for.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Integrated Help & Time-Saving Aliases",
      data: (
        <>
          <ul>
            <li>Use help commands to quickly access information and get step-by-step guidance on using the app.</li>
            <li>Save time with time-saving aliases that make it easy to access your favorite commands.</li>
            <li>Master the app with detailed instructions and tips to enhance your diamond search experience.</li>
         </ul>
        </>
      ),
    },
    {
      title: "Streamlined Workflow & Market Insights",
      data: (
        <>
          <ul>
            <li>Streamline your workflow by accessing all the information you need in one place.</li>
            <li>Compare diamonds side-by-side, view certificates, and watch videos to make informed decisions.</li>
            <li>Analyze US and global diamond statistics to uncover market trends and opportunities.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Supplier Inventory Management",
      data: (
        <>
          <ul>
            <li>Upload your inventory and supplier inventories to expand your market reach.</li>
            <li>Query multiple suppliers simultaneously to find the best deals and maximize your profits.</li>
            <li>Check the prices in chart form to see the price trends.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Excel-Like Editing & Filtering",
      data: (
        <>
          <ul>
            <li>Edit your data directly in the app, just like you would in Excel.</li>
            <li>Filter your data to find the diamonds that meet your specific criteria.</li>
            <li>Instant search results with detailed information on each diamond.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Diamond Assist Guide",
      data: (
        <>
          <ul>
            <li>Use help commands to quickly access information and get step-by-step guidance on using the app.</li>
            <li>Master the app with detailed instructions and tips to enhance your diamond search experience.</li>
            <li>Check our tutorials on YouTube for more information.</li>
          </ul>
        </>
      ),
    },
  ];

  youtubeInfo = [
    {
      title: "Video 1: Diamond Search, Pivot Tables, Sorting & Downloading",
      ytLink:
        "https://www.youtube.com/watch?v=A5k43UBVbNg&list=PLq4IM39R5r2MctIOvy0fdtrPJ3SQpd_lh",
      channel: "V360 Studio",
      channelLink: "https://www.youtube.com/channel/UCmVs_mnU_QniwTgMgXzmsjA",
      thumbnail:
        "https://i.ytimg.com/vi/A5k43UBVbNg/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARgvIE0ofzAP&rs=AOn4CLDXRYwziygsrFUaW3ZXIF9R8lLioQ",
    },
    {
      title: "Video 2: Easy Query Creation, Customization, and Saving",
      ytLink: "https://www.youtube.com/watch?v=0VBO8LFdoKc&t=6s",
      channel: "V360 Studio",
      channelLink: "https://www.youtube.com/channel/UCmVs_mnU_QniwTgMgXzmsjA",
      thumbnail:
        "https://i.ytimg.com/vi/0VBO8LFdoKc/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARgsIEwofzAP&rs=AOn4CLDgdsr0Dq9TBoXDnERQUEuIMNG4Gw",
    },
    {
      title: "Video 3: Master Your Diamond Data: Excel-Like Editing & Filtering",
      ytLink: "https://www.youtube.com/watch?v=EuUqIE1ffAo",
      channel: "V360 Studio",
      channelLink: "https://www.youtube.com/channel/UCmVs_mnU_QniwTgMgXzmsjA",
      thumbnail: "https://i.ytimg.com/vi/EuUqIE1ffAo/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARgkIEgofzAP&rs=AOn4CLDlXrO3UDcEBq2eHQdP_OD2lm2O7A",
    },
    {
      title: "Video 4: Your Diamond Assist Guide: Help Commands & Time-Saving Aliases",
      ytLink: "https://www.youtube.com/watch?v=1pDnnQMmFbw",
      channel: "V360 Studio",
      channelLink: "https://www.youtube.com/channel/UCmVs_mnU_QniwTgMgXzmsjA",
      thumbnail: "https://i.ytimg.com/vi/1pDnnQMmFbw/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARgjIEcofzAP&rs=AOn4CLC7NdRx-ItG86Cx6HsnVStxDdrDMQ",
    },
    {
      title: "Video 5: Streamline Your Workflow: Certificates, Videos & Diamond Comparisons",
      ytLink: "https://www.youtube.com/watch?v=T5Bpe04pf5s&t=5s",
      channel: "V360 Studio",
      channelLink: "https://www.youtube.com/channel/UCmVs_mnU_QniwTgMgXzmsjA",
      thumbnail: "https://i.ytimg.com/vi/T5Bpe04pf5s/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARg1IFIofzAP&rs=AOn4CLC371WmMZHi24aIW1ZMGgBetSU5tA",
    },
    {
      title: "Video 6: Expand Your Market Reach: Uploading Your & Supplier Inventories",
      ytLink: "https://www.youtube.com/watch?v=fDWXwbRIH9c",
      channel: "V360 Studio",
      channelLink: "https://www.youtube.com/channel/UCmVs_mnU_QniwTgMgXzmsjA",
      thumbnail: "https://i.ytimg.com/vi/fDWXwbRIH9c/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARhlIGUoZTAP&rs=AOn4CLAq2rAHxqr4a-EOzbTYGUV-qtD4mA",
    },
    {
      title: "Video 7: Master the Market: Querying Multiple Suppliers Simultaneously",
      ytLink: "https://www.youtube.com/watch?v=K4lL2zUMr1A",
      channel: "V360 Studio",
      channelLink: "https://www.youtube.com/channel/UCmVs_mnU_QniwTgMgXzmsjA",
      thumbnail: "https://i.ytimg.com/vi/K4lL2zUMr1A/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARhCIGUoQjAP&rs=AOn4CLARqA6kE43NYgatQx8DmgkMT-fvLQ"
    },
    {
      title: "Video 8: Uncover Market Insights: Analyze US & Global Diamond Statistics",
      ytLink: "https://www.youtube.com/watch?v=qGPoAAyAmSE",
      channel: "V360 Studio",
      channelLink: "https://www.youtube.com/channel/UCmVs_mnU_QniwTgMgXzmsjA",
      thumbnail: "https://i.ytimg.com/vi/qGPoAAyAmSE/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARgkIEcofzAP&rs=AOn4CLBrUPAO5wHCPrFERRQWTLL-tXRRKw"
    }
  ];

  setShowTut = (newVal) => {
    this.setState({ showTut: newVal });
  };

  renderAppInfoCard = (info, index) => (
    <div key={`appInfoCard-${index}`} className="appInfoCard">
      <div className="appInfoTitle">
        {info.title}
      </div>
      <div className="appInfoData">{info.data}</div>
    </div>
  );

  renderYoutubeInfoCard = (info, index) => (
    <div key={`youtubeInfoCard-${index}`} className="youtubeInfoCard">
      <a href={info.ytLink} target="_blank" rel="noopener noreferrer">
        <img src={info.thumbnail} alt={info.title} className="youtubeThumbnail" />
      </a>
      <div className="youtubeInfoData">
        <div className="youtubeTitle">
          <a href={info.ytLink} target="_blank" rel="noopener noreferrer">
            {info.title}
          </a>
        </div>
        <div className="youtubeChannel">
          <a href={info.channelLink} target="_blank" rel="noopener noreferrer">
            {info.channel}
          </a>
        </div>
      </div>
    </div>
  );

  render = () => {
    return (
      <>
        <Navigation
          loggedIn={false}
          setShowTut={this.setShowTut}
          handleGoogleSignIn={handleGoogleSignIn}
        />
        {this.state.showTut ? <Tutorial setShowTut={this.setShowTut} /> : null}
        <div className="mainContainer">
          <h1 className="mainTitle">Diamond Assist</h1>
          <div className="cardContainer">
            <div className="youtubeInfoCards">
              {this.youtubeInfo.map((info, index) =>
                this.renderYoutubeInfoCard(info, index)
              )}
            </div>
            <div className="appInfoCards">
              {this.appInfo.map((info, index) =>
                this.renderAppInfoCard(info, index)
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
}