import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
} from 'firebase/auth';
import { signInWithPopup } from "firebase/auth";
import { firebaseConfig,app } from '../../config/firebase';

export const auth = getAuth();
export const authProviders = new GoogleAuthProvider();

export const handleGoogleSignIn = (event) => {
    event.preventDefault();
    try {
      signInWithPopup(auth,authProviders).then((response) => {

      })
      .catch((err) => {
        console.error(err);
      });
    } catch (error) {
        console.error(error);
    }
  };