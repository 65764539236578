import React from 'react';
import { connect } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import axios from 'axios';
import {  postQuery, getUserVendors,getFirestoreDoc, updateLogDocument } from '../../../../util/firebase-firestore';
import { uploadToStorage, listBucketObjects, getFromStorage } from '../../../../util/firebase-storage';
import FileInput from './FileInput';
import './uploadFiles.css';
import { userConvertApi, getDatesApi, fileUploadBucket, getAvailableNameApi } from "../../../../../config/projectconfigs"
import { firebaseConfig } from '../../../../../config/firebase';
import AddVendorModalContainer from '../../../../util/AddVendorModalContainer';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import excelDownload from "../../../../../assets/images/excelDownload.png"
import createNotification from '../../../../util/Notification';
import { logsCollection } from '../../../../../config/projectconfigs';

class UploadFiles extends React.Component {
    constructor(props) {
        super(props);
        this.logRef = React.createRef();
    }

    state = {
        userID: this.props.userID,
        operationMode: 0,
        fileInputs: [[{
            id: Date.now(),
            selectedFile: null,
            filePath: ''
        }]],
        nonParsedFiles: [],
        nonParsedFilePaths: [],
        date: (new Date()).toISOString().substr(0, 10),
        inputDisabled: false,
        mergeEnabled: true,
        merged: null,
        downloadLatestDate: '',
        convertedFileData: [
            // [
            //     0,
            //     "f",
            //     "Ankit 06-06_output.xlsx"
            // ],
            // [
            //     0,
            //     "s",
            //     "KAPU(06-06)_output.xlsx"
            // ],
            // [
            //     0,
            //     "Website_Rapnet_2023_10_05_Marquise_output.xlsx"
            // ]
        ],
        convertedFilesDate: null,
        mergedFileDownloadUrl: [],
        userType: this.props.userType,
        vendors: [],
        VENDORNAMES: [''],
        fileDates: {
            0: null,
            1: null
        },
        loadedUploadedFiles: [],
        logs: [
            // {
            //     "text": "Files Uploading Started",
            //     "timeStamp": "2024-02-19T06:19:58.230Z",
            //     "logType": "default"
            // },
            // {
            //     "text": "DDPLStock-22-Jun-2023 21_05_30.xlsx Upload Success",
            //     "timeStamp": "2024-02-19T06:21:18.835Z",
            //     "logType": "success"
            // },
            // {
            //     "text": "DHARAM.csv Upload Success",
            //     "timeStamp": "2024-02-19T06:21:18.845Z",
            //     "logType": "success"
            // },
            // {
            //     "text": "DHARMANANDAN(06-06).xlsx Upload Success",
            //     "timeStamp": "2024-02-19T06:21:18.853Z",
            //     "logType": "success"
            // },


            // {
            //     "text" : "test Text",
            //     "timeStamp" : (new Date()).toISOString(),
            //     "logType" : "default"
            // },
            // {
            //     "text" : "test Text",
            //     "timeStamp" : (new Date()).toISOString(),
            //     "logType" : "success"
            // },
            // {
            //     "text" : "test Text",
            //     "timeStamp" : (new Date()).toISOString(),
            //     "logType" : "error"
            // } ,
            // {
            //     "text" : "test Text",
            //     "timeStamp" : (new Date()).toISOString(),
            //     "logType" : "warning"
            // },
            // {
            //     "text" : "test Text",
            //     "timeStamp" : (new Date()).toISOString(),
            //     "logType" : "default"
            // },
            // {
            //     "text" : "test Text",
            //     "timeStamp" : (new Date()).toISOString(),
            //     "logType" : "success"
            // },
            // {
            //     "text" : "test Text",
            //     "timeStamp" : (new Date()).toISOString(),
            //     "logType" : "error"
            // } ,
            // {
            //     "text" : "test Text",
            //     "timeStamp" : (new Date()).toISOString(),
            //     "logType" : "warning"
            // }
        ]

    }

    getTimeStamp = (epoch) => {
        let currentDateObj = new Date(epoch)
        const year = currentDateObj.getFullYear();
        const month = String(currentDateObj.getMonth() + 1).padStart(2, '0');
        const day = String(currentDateObj.getDate()).padStart(2, '0');
        const hours = String(currentDateObj.getHours()).padStart(2, '0');
        const minutes = String(currentDateObj.getMinutes()).padStart(2, '0');

        let timeZoneAbbreviation = currentDateObj.toLocaleTimeString('en', { timeZoneName: 'longGeneric' });
        timeZoneAbbreviation = timeZoneAbbreviation.split(" ").slice(2)
        timeZoneAbbreviation = (timeZoneAbbreviation.length > 1) ? (timeZoneAbbreviation.map((literal) => { return literal[0] }).join("")) : (timeZoneAbbreviation[0])

        const timeStamp = `${year}-${month}-${day} ${hours}:${minutes} ${timeZoneAbbreviation}`
        return timeStamp
    }

    addLog = (text, type) => {
        let tempLogs = this.state.logs
        let timeStamp = Date.now()

        let logObject = {
            "text": text,
            "timeStamp": timeStamp,
            "logType": type
        }

        tempLogs.push(logObject)
        // this.setState({ logs: tempLogs }, () => {
        //     this.logRef.current.scrollTop = this.logRef.current.scrollHeight;
        // })

        updateLogDocument("logs", this.props.userID, { "logs": tempLogs })
        
    }

    // logRef = React.useRef(null)


    fetchLogs = () => {
        // console.log(logsCollection,this.props.userID);
        getFirestoreDoc(logsCollection, this.props.userID, (res) => {
            try {
                let prevLogs = res["logs"].slice(-15)
                this.setState({ logs: prevLogs }, () => {
                    this.logRef.current.scrollTop = this.logRef.current.scrollHeight;
                })
            } catch (error) {
                this.setState({ logs: [] })
            }
            
        },(changedDoc)=>{
            this.setState({ logs: changedDoc["logs"] }, () => {
                this.logRef.current.scrollTop = this.logRef.current.scrollHeight;
            })
        },(err)=>{
            this.setState({ logs: [] })
        })
    }

    convertJson(jsonData) {
        const keys = Object.keys(jsonData)

        const rows = Object.keys(jsonData[keys[0]])


        const data = Object.values(jsonData)
        let modifiedJsonData = []

        rows.forEach(ind => {
            let values = data.map(col => {
                return col[ind]
            })
            let obj = keys.reduce((obj, key, index) => {
                obj[key] = values[index];
                return obj;
            }, {});
            modifiedJsonData.push(obj)
        })

        return modifiedJsonData
    }

    addNotification(notfObj) {
        const curr_notf = this.props.notfList
        this.props.setNotfList([...curr_notf, notfObj])
    }


    convertFiles(userId, date, uploadedFiles, callback, vendor_name = null) {
        uploadedFiles = uploadedFiles.map((fileName)=>{
            if(fileName.endsWith("/"))fileName = fileName.slice(0,-1)
            return fileName
        })
        let payload = { "userId": userId, "date": date, "uploadedFiles": uploadedFiles, "projectId": firebaseConfig["projectId"],"isRapnet":"False"};
        if (vendor_name) {
            payload['vendor_name'] = vendor_name
        }
        try {
            axios.defaults.withCredentials = false
            axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

            axios.post(userConvertApi, { params: payload }, {
                crossDomain: true,
                timeout: 100000
            }).then(response => {
                // console.log(response);
                // console.log(response.data);
                callback(response);
            })
                .catch(error => {
                    // console.log(error);
                });
        } catch (error) {
            console.error("Error merging supplier file:", error);
            return null;
        }

    }



    getAvailableName = (curr_name, BASE_DIR, callback) => {
        
        try {
            let name_list = []
            listBucketObjects(`${BASE_DIR}/`).then((res) => {
                res.items.forEach(fileItem => {
                    name_list.push(fileItem.name)
                })
            }).catch((err)=>{
                // console.log("error in list bucket of files ",err);
                name_list = []
            })
            if(name_list.length==0)
            {
                return callback(curr_name)
            }
            const payload = { "base_string": curr_name, "string_list": name_list.join("=") }
            axios.defaults.withCredentials = false
            axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

            axios.get(getAvailableNameApi, { params: payload }, {
                crossDomain: true
            }).then(response => {
                // return response.data.available_name;
                return callback(response.data.available_name)

            })
                .catch(error => {
                    // console.log(error);
                    // return curr_name
                    return callback(curr_name)

                });
        } catch (error) {
            // console.log("Error Fetching available name :", error);
            // return curr_name
            return callback(curr_name)

        }
    }



    handleConvertSubmit = (event) => {

        event.preventDefault();
        let date = this.state.date.replaceAll(/[/ \-]/g, "")

        const BASE_DIR = `${fileUploadBucket}/${this.props.userID}/${date}/${this.state.userType ? 'Vendor_files' : 'User_files'}`

        let isUploaded = false

        let tempFileinputs = []
        let tempVENDORNAMES = []
        let userType = this.state.userType

        this.state.fileInputs.forEach((fileInputList, index) => {
            tempFileinputs.push(...fileInputList)
            if (userType) tempVENDORNAMES.push(...Array(fileInputList.length).fill(this.state.VENDORNAMES[index]))
        })
        this.setState({ fileInputs: tempFileinputs, VENDORNAMES: tempVENDORNAMES })


        this.addLog("Files Uploading Started", "default")

        let uploadPromises = []
        let uploadedFiles = []

        tempFileinputs.map((fileInput, index) => {
            if (fileInput.selectedFile) {
                isUploaded = true

                if (userType) fileInput.VENDORNAME = tempVENDORNAMES[index];

                this.getAvailableName(fileInput.selectedFile.name, BASE_DIR,(availableName) => {
                    let filepath;
                    if (userType) filepath = `${BASE_DIR}/${fileInput.VENDORNAME}/${availableName}/`;
                    else filepath = `${BASE_DIR}/${availableName}/`;

                    uploadedFiles.push(filepath)
                    let uploadPromise = uploadToStorage(filepath, fileInput.selectedFile)

                    uploadPromises.push(uploadPromise)

                    if(uploadPromises.length==tempFileinputs.length)
                    {
                        Promise.all(uploadPromises).then((files) => {
                            this.setState({ convertedFilesDate: this.state.date })
                            // listBucketObjects(`${BASE_DIR}/`).then((res) => {console.log(res.items);})
                            if (this.state.userType) {
                                Array.from(new Set(tempVENDORNAMES)).forEach((vendor_name, index) => {
                                    this.convertFiles(this.props.userID, date,uploadedFiles, (response) => {
                                        let statusCodes = response.data.statusCodes
                                        for (let filePath in statusCodes) {
                                            let fileName = filePath.split("/").slice(-2).join(":")

                                            if (statusCodes[filePath] == 200) {
                                                // this.addLog(fileName + " upload success", "success")
                                                this.addNotification(createNotification(fileName + ' uploaded successfully', 'success', `${fileName} successfully uploaded for date ${this.state.date}`))

                                            }
                                            else {
                                                // this.addLog(fileName + " upload error", "error")
                                                this.addNotification(createNotification(fileName + ' upload failed', 'error', `${fileName} upload failed for date ${this.state.date}`))
                                            }
                                        }
                                        this.fetchFileDates(this.state.userType, (filesDates, defaultVal) => {
                                            this.fetchLoadedUploadedFiles(defaultVal)

                                        }, true)
                                    }, vendor_name)
                                })

                            }
                            else {

                                this.convertFiles(this.props.userID, date, uploadedFiles, (response) => {
                                    let statusCodes = response.data.statusCodes

                                    for (let filePath in statusCodes) {
                                        let fileName = filePath.split("/").slice(-1).join(":")
                                        if (statusCodes[filePath] == 200) {
                                            // this.addLog(fileName + " upload success", "success")
                                            this.addNotification(createNotification(fileName + ' uploaded successfully', 'success', `${fileName} successfully uploaded for date ${this.state.date}`))

                                        }
                                        else {
                                            // this.addLog(fileName + " upload error", "error")
                                            this.addNotification(createNotification(fileName + ' upload failed', 'error', `${fileName} upload failed for date ${this.state.date}`))
                                        }
                                    }


                                    this.fetchFileDates(this.state.userType, (filesDates, defaultVal) => {
                                        this.fetchLoadedUploadedFiles(defaultVal)

                                    }, true)
                                })

                            }
                        }).catch(() => {
                            this.addLog("Upload Error", "error")
                            this.addNotification(createNotification('Error in uploading files', 'error', `Error in uploading files for date ${this.state.date}`))
                        })
                    }

                })
            }
        });

        if (!isUploaded) {
            this.addNotification(createNotification('No Files Selected', 'warning'));
        }

        this.setState({
            userID: this.props.userID,
            fileInputs: [[{
                id: Date.now(),
                selectedFile: null,
                filePath: ''
            }]],
            inputDisabled: false,
            mergeEnabled: true,
            nonParsedFiles: [],
            merged: null,
            latestMerged: null,
            downloadLatestDate: ''
        });
    }

    addNewVendor = (vendor) => {
        // console.log(this.state.vendors,vendor);
        if (!this.state.vendors.map((doc) => { return doc["VENDORNAME"]; }).includes(vendor["VENDORNAME"])) {
            postQuery('userVendors', {
                ...vendor,
                userID: this.props.userID
            })
            this.setState({
                vendors: [...this.state.vendors, { ...vendor }]
            })
        }

    }



    renderUserVendors = () => {

        let vendorList = this.state.vendors.map((vendor, index) => {
            return (
                <option value={vendor.VENDORNAME} key={index}>{vendor.VENDORNAME}</option>
            )
        });

        return [
            <option disabled selected value="">--Select Vendor--</option>,
            ...vendorList,
            (vendorList.length === 0) ? <option disabled>No vendors available</option> : null,
        ];
    }

    addFileInput = () => {
        const N = this.state.fileInputs.length;
        if (this.state.fileInputs[N - 1][0].selectedFile === null) {
            this.addNotification(createNotification('Please select file first !', 'warning'));
            return;
        }

        if (N === 5) {
            this.addNotification(createNotification('Limit reached for User Files !', 'warning'));
            return;
        }

        this.setState({
            fileInputs: [...this.state.fileInputs, [{ selectedFile: null, id: Date.now(), filePath: '' }]]
        })

    }

    onFileSelect = (id, selectedFiles) => {

        let filePath = ''
        const rest = this.state.fileInputs.filter(input => input[0].id != id)
        let files = Object.values(selectedFiles).map((selectedFile) => {
            return { id, selectedFile, filePath }
        })
        this.setState({

            fileInputs: [...rest, files]
        })
    }


    removeFileInput = (id, index) => {

        if (this.state.fileInputs.length === 1) {
            this.addNotification(createNotification('Must upload atleast One User file', 'warning'));
            return;
        }

        this.setState({
            fileInputs: this.state.fileInputs.filter((ele, ind) => ind !== index)
        })
    }

    setVendorInput = (e) => {
        let temp = this.state.VENDORNAMES
        temp[e.target.name] = e.target.value
        this.setState({ VENDORNAMES: temp });
    }

    renderFileInputs = () => {
        return this.state.fileInputs.map((fileInput, index) => {
            let id = fileInput[0].id
            return (
                <div>
                    {this.state.userType ? <>
                        <div className="vendorSelector form-group mt-2 mb-1 w-50">
                            <label htmlFor="VENDORNAME">Select Vendor</label>
                            <Tooltip arrow title="Select Vendor Name according to uploaded file">
                                <select
                                    name={index}
                                    className='form-select'
                                    value={this.state.VENDORNAMES[index]}
                                    onChange={this.setVendorInput}
                                    id="VENDORNAME"
                                    required
                                >
                                    {this.renderUserVendors()}
                                </select>
                            </Tooltip>
                        </div>
                    </> : <></>}
                    <div className="form-group pt-3 pb-3">
                        <label htmlFor={id}>Select your file</label>
                        <FileInput
                            id={id}
                            index={index}
                            onFileSelect={this.onFileSelect}
                            removeFileInput={this.removeFileInput}
                        />
                        <small id="fileHelp" className="form-text text-muted">Only xlsx , xls , csv  files are allowed</small>

                    </div>
                </div>

            )
        })

    }

    fetchPrevConvertedFiles = () => {
        let date = this.state.date.replaceAll(/[/ \-]/g, "")
        const payload = { "user_id": this.props.userID, "isUser": this.state.userType ? "False" : "True", "projectId": firebaseConfig["projectId"], date: date }
        try {
            axios.defaults.withCredentials = false
            axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
            axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

            axios.get(getDatesApi, { params: payload }, {
                crossDomain: true
            }).then(response => {
                let fileData = response.data.files.map((data) => { return [1, ...data] })
                this.setState({ convertedFileData: fileData, convertedFilesDate: date })
                // console.log(fileData, date);
            })
                .catch(error => {
                    // console.log(error);
                    this.setState({ convertedFileData: [], convertedFilesDate: null });
                });
        } catch (error) {
            // console.log("Error Fetching latest dates :", error);
            this.setState({ convertedFileData: [], convertedFilesDate: null });
        }
    }


    downloadUploadedFile = async (e) => {

        let date = this.state.fileDate.replaceAll("/", "")
        let filePath = e.currentTarget.getAttribute("filePath").split("/")

        // const BASE_DIR = `${summaryBucket}/${this.props.userID}/${date}/${(this.state.userType) ? 'Vendor_files' : 'User_files'}/`;
        const BASE_DIR = `${fileUploadBucket}/${this.props.userID}/${date}/${(this.state.userType) ? 'Vendor_files' : 'User_files'}/`;
        if (filePath.length == 2) BASE_DIR = `${BASE_DIR}${filePath[0]}/`

        let fileName = filePath.slice(-1)[0]

        Promise.resolve(getFromStorage(BASE_DIR, fileName))
            .then((downloadURL) => {
                let type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

                let linkEle = document.createElement("a")
                linkEle.href = downloadURL
                linkEle.download = fileName
                linkEle.click()

                this.addNotification(createNotification(fileName + ' Downloaded Successfully', 'success'))
            })
            .catch(() => {
                this.addNotification(createNotification('Error While downloading' + fileName, 'error'))
            })





    }

    fetchFileDates = async (userType, callback, forceUpdate = false) => {
        let isUser;
        if (!forceUpdate && this.state.fileDates[userType]) {
            let dateList = this.state.fileDates[userType]
            let defaultVal = dateList.length ? dateList.slice(-1)[0] : null
            // console.log(defaultVal);
            this.setState({ fileDate: defaultVal })
            if (callback) callback(dateList, defaultVal)
            return
        }
        if (userType) isUser = "False"
        else isUser = "True"

        const uid = this.props.userID;

        let tempFileDates = this.state.fileDates
        const payload = { "user_id": uid, "isUser": isUser, "projectId": firebaseConfig["projectId"] }
        try {
            axios.defaults.withCredentials = false
            axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
            axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

            axios.get(getDatesApi, { params: payload }, {
                crossDomain: true
            }).then(response => {
                let dateList = response.data.files
                tempFileDates[userType] = dateList

                let defaultVal = dateList.length ? dateList.slice(-1)[0] : null
                this.setState({ fileDate: defaultVal })
                this.setState({ fileDates: tempFileDates })
                if (callback) callback(tempFileDates, defaultVal)
            })
                .catch(error => {
                });
        } catch (error) {
            console.log("Error Fetching latest dates :", error);
            tempFileDates[userType] = []
            this.setState({ fileDates: tempFileDates })
            if (callback) callback([], null)

        }
    }

    fetchLoadedUploadedFiles = (fileUploadDate, userType = this.state.userType) => {
        if(fileUploadDate)
        {
            fileUploadDate = fileUploadDate.replaceAll(/[/ \-]/g, "")
            // let filePath = `${summaryBucket}/${this.props.userID}/${fileUploadDate}/${(userType) ? 'Vendor_files' : 'User_files'}/`
            let filePath = `${fileUploadBucket}/${this.props.userID}/${fileUploadDate}/${(userType) ? 'Vendor_files' : 'User_files'}/`


            listBucketObjects(filePath).then((res) => {

                if (!userType) {
                    let loadedUploadedFiles = res.items.map((itemRef) => {
                        return [itemRef.name];
                    });
                    this.setState({ loadedUploadedFiles: loadedUploadedFiles })
                }
                else {
                    let loadedUploadedFiles = []
                    res.prefixes.forEach((folderRef, idx, arr) => {
                        listBucketObjects(filePath + folderRef.name + "/").then((resFiles) => {
                            let vendorFiles = resFiles.items.map((itemRef) => {
                                return [folderRef.name, itemRef.name]
                            })
                            loadedUploadedFiles.push(...vendorFiles)
                            this.setState({ loadedUploadedFiles: loadedUploadedFiles })
                            // console.log(uploadedFiles);
                        })
                    })
                }
            })
        }
        else 
        {
            this.setState({ loadedUploadedFiles: [] })
        }


        
    }





    renderFileDates = (fileDates) => {
        return fileDates.map((date) => {
            return <MenuItem value={date}>{date}</MenuItem>
        })
    }

    handleFileDate = (e) => {
        this.setState({ fileDate: e.target.value, uploadedFiles: null })
        this.fetchLoadedUploadedFiles(e.target.value)
    }

    renderDateSelection = () => {
        let fileDates = this.state.fileDates[this.state.userType]

        if(fileDates.length>0)
        {
            return <>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Date</InputLabel>
                <Tooltip arrow title="Select Date of uploaded file" placement="top">
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={this.state.fileDate}
                        label="Source"
                        onChange={this.handleFileDate}
                        defaultValue={this.state.fileDate}
                    >
                        {this.renderFileDates(fileDates)}
                    </Select>
                </Tooltip>
            </FormControl>
            </>
        }
        else 
        {
            return <></>
        }
        
    }

    renderLoadedUploadedFiles = () => {
        if(this.state.loadedUploadedFiles.length>0)
        {
            return (<>
                {(this.state.loadedUploadedFiles.map((file, index) => {
                    let fileName = file.join(" : ")
                    return (<>
                        <div filePath={file.join("/")} onClick={this.downloadUploadedFile} className='uploadedFile'>
                            <span title={fileName}>
                                {fileName}
                            </span>
                            <button><img alt="excel" src={excelDownload}></img> </button>
                        </div>
                    </>)
                }))}
            </>)
        }
        else 
        {
            return <>
            No Recent Files
            </>
        }
        
    }



    renderLogs = () => {
        if(this.state.logs.length>0)
        {
            return this.state.logs.map((log) => {
                let timeStampText = this.getTimeStamp(log.timeStamp) 
                return (<>
                    <div className={`log ${log.logType}`}>
                        <span title={timeStampText} className='timeStamp'>{timeStampText}</span>
                        <span title={log.text} className='text'>{log.text}</span>
                    </div>
                </>)
            })
        }
        else {
            return <span>No Logs</span>
        }
        
    }



    handleSubmit(event) {
        event.preventDefault();
    }



    componentDidMount = () => {

        this.fetchFileDates(this.state.userType, (filesDates, defaultVal) => {
            this.fetchLoadedUploadedFiles(defaultVal)

        })

        this.fetchLogs()

    }

    toggleOperationMode = (e) => {
        let newMode = parseInt(e.target.getAttribute("mode"))
        if (newMode != this.state.userType) {

            let currMode = this.state.userType


            this.setState({ userType: newMode })

            this.fetchFileDates(newMode, (filesDates, defaultVal) => {
                this.fetchLoadedUploadedFiles(defaultVal, newMode)
            })

            if (newMode) {
                getUserVendors(this.props.userID)
                    .then(vendors => {
                        this.setState({ vendors: vendors })
                    })
                    .catch(err => console.error(err));
            }

        }
    }

    render() {

        this.props.setShowTut(false)
        return (
            <>
                <div className='uploadFilesDiv'>
                    <div className='uploadFilesOptionDiv'>
                        <div className='buttonDiv'>
                            <Tooltip arrow title="Select to Upload Data Files in Inventory">
                                <div className={`${this.state.userType ? '' : 'modeSelected'} modeButton uploadconvertButton`} mode="0" onClick={this.toggleOperationMode}>
                                    <div mode="0">
                                        My Company
                                    </div>
                                </div>
                            </Tooltip>
                        </div>

                        <div className='buttonDiv'>
                            <Tooltip arrow title="Select to Group Multiple Files Together">
                                <div className={`${this.state.userType ? 'modeSelected' : ''} modeButton mergeExportButton`} mode="1" onClick={this.toggleOperationMode}>

                                    <div mode="1">
                                        Supplier
                                    </div>
                                </div>
                            </Tooltip>
                        </div>

                    </div>
                    <div className='uploadconvertOperationDiv'>
                        <div className='FileSection'>
                            <div className='dateSelector'>
                                <div>
                                    <label htmlFor="date">Enter Date</label>
                                    <Tooltip arrow title="Select Date associated with Data file">

                                        <input
                                            type="date"
                                            className="form-control"
                                            id="date"
                                            value={this.state.date}
                                            onChange={(e) => this.setState({ date: e.target.value })}
                                            required
                                        />
                                    </Tooltip>
                                </div>
                                {this.state.userType ? <>
                                    <AddVendorModalContainer addNewVendor={this.addNewVendor} />
                                </> : <></>}


                            </div>
                            <div className='fileSelector'>
                                {this.renderFileInputs()}
                                <div className="form-group mx-2 mt-3 mb-2 ">
                                    <Tooltip arrow title="Add More File Slots">
                                        <button className="btn btn-outline" onClick={this.addFileInput} disabled={this.state.disabled} type="button">Select More Files</button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className='convertButtondiv'>
                                <button onClick={this.handleConvertSubmit} type="button" className="btn btn-success">
                                    <span>
                                        Upload Files
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className='uploadedFilesDiv'>
                            <div className='uploadedFilesList'>
                                <span>Recent Data</span>
                                {(this.state.fileDates[this.state.userType]) ?
                                    (<>
                                        {this.renderDateSelection()}
                                        <div className="uploadedFiles">

                                            {(this.state.loadedUploadedFiles) ?
                                                (<>
                                                    {this.renderLoadedUploadedFiles()}
                                                </>) :
                                                (<>
                                                    <CircularProgress className="fileDatesLoader" />
                                                </>)}
                                        </div>
                                    </>) :
                                    (<>
                                        <div className='loader'>
                                            <CircularProgress className="fileDatesLoader" />
                                        </div>

                                    </>)}
                            </div>

                            <div className='uploadedFilesLogs'>
                                <span>Uploaded Data</span>
                                <div ref={this.logRef} className='LogsDiv'>
                                    {this.renderLogs()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    if (!state.user) return {};

    return {
        userID: state.user.uid
    }
}

export default connect(mapStateToProps, {})(UploadFiles);