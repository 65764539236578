import React from "react";
// import SignIn from "../../Authentication/SignIn";
import SignIn from "../../Authentication/SignIn";
// import { BrowserRouter, Route } from "react-router-dom";
import { BrowserRouter, Route,Navigate,Routes} from "react-router-dom";

// import ViewMedia from "../AppContainer/ViewMedia/ViewMedia";
// import ViewVideo from "../AppContainer/ViewMedia/ViewVideo";
/**-
 * This is for rendering the things where the user is not logged in
 * 1) The signin page
 * 2) The image links
 * 3) The video links
 */
export const NoLoginReq = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<SignIn/>} />
          <Route path="*" element={<Navigate to="/" replace />} /> 
        </Routes>
      </BrowserRouter>
    </>
  );
};
