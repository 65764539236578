/*

 *
 * This is where we register the routes,
 * For ex: the /image/id is where the dynamic page is rendered based on the id
 * The routes for analysis and inventory have also been added there
 * Those are currently disabled there
 * To enable those, uncomment the
 */



import React, { useState } from "react";
import Messenger from "./AppContainerChildren/Messenger";
import Navigation from "./AppContainerChildren/Navigation";
import UploadFiles from "./AppContainerChildren/uploadFiles/UploadFiles";
import Analysis from "./AppContainerChildren/Analysis/Analysis";
import { BrowserRouter, Route,Navigate,Routes} from "react-router-dom";
import Tutorial from "./AppContainerChildren/Tutorial";

export const AppContainer = (props) => {
  let [notfList, setNotfList] = useState([])
  let [showTut, setShowTut] = useState(true)
    if(sessionStorage.getItem("pageType")===null)
    {
      sessionStorage.setItem("pageType","default")
      sessionStorage.setItem("showTut","true")
    }
    return (
    <>
        <BrowserRouter>
        <Navigation loggedIn={true} setShowTut={setShowTut} notfList={notfList} setNotfList={setNotfList} />
        {(showTut)?(<Tutorial setShowTut={setShowTut} />):(<></>)}
        <Routes>
        <Route exact path='/' element={<Messenger setShowTut={setShowTut}  fileType={props.fileType} notfList={notfList} setNotfList={setNotfList} setFileType={props.setFileType} />} />
          
        {/* </Route> */}

        <Route exact path='/uploadFiles' element={<UploadFiles userType={0} setShowTut={setShowTut} notfList={notfList} setNotfList={setNotfList} />} />

        {/* <Route  exact path='/uploadFiles' > */}
          
        {/* </Route> */}
        <Route path="*" element={<Navigate to="/" replace />} /> 
        {/* <Route exact path='/analysis' >
          <Analysis setShowTut={setShowTut} notfList={notfList} setNotfList={setNotfList} />
        </Route> */}
        </Routes>
      </BrowserRouter>
    </>
  );
};
/**
 * Test this file by going to the route mentioned here after starting the page and check the route is reachable or not
 */
