import React from "react";
import { connect } from "react-redux";
import MessageDetail from "./MessageListChildren/MessageDetail";
import "../../../../../../src/assets/css/MessageList.css";

class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.freezeScroll) {
      setTimeout(() => this.scrollToBottom(), 500);
    }
  }

  componentDidUpdate() {
    if (!this.props.freezeScroll) {
      setTimeout(() => this.scrollToBottom(), 500);
    }
  }

  removeWaitMessages(currMessages) {
    return currMessages.filter((msg, ind) => ind === currMessages.length - 1 || !(msg.text && msg.text.startsWith("Please wait")));
  }

  handleClearMessage(currMessages) {
    let clearIdx = currMessages.slice().reverse().findIndex(msg => msg.queryMode === "clear");
    if (clearIdx !== -1) {
      clearIdx = currMessages.length - clearIdx - 1;
      currMessages = currMessages.slice(clearIdx + 1);
    }
    return currMessages;
  }

  sortTimeStamp(currMessages) {
    return currMessages.sort((l, r) => l.timeStamp - r.timeStamp);
  }

  applyMessageOperation() {
    let currMessages = this.props.messages;
    try {
      currMessages = this.handleClearMessage(currMessages);
      currMessages = this.removeWaitMessages(currMessages);
      currMessages = this.sortTimeStamp(currMessages);
      return currMessages;
    } catch (error) {
      return currMessages;
    }
  }

  renderBotReply(message) {
    return <div className="ui segment">{message}</div>;
  }

  renderMessages() {
    let messages = this.applyMessageOperation();
    return messages.map(message => (
      <MessageDetail 
        key={message.key}
        setFreezeScroll={this.props.setFreezeScroll}
        columnPreference={this.props.columnPreference}
        appendQuery={this.props.appendQuery}
        checked={this.props.currResponseId === message.key}
        docID={message.key}
        setResponse={this.props.setResponse}
        setPriceSeries={this.props.setPriceSeries}
        {...message}
        fileType={this.props.fileType}
      />
    ));
  }

  scrollToBottom() {
    if (document.activeElement !== document.getElementById("queryTextInput") && this.messagesEnd) {
      this.messagesEnd.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    }
  }

  render() {
    return (
      <div className="container-fluid div1">
        <div className="ui list divided">
          {this.renderMessages()}
          <div className="dummy-div div2" ref={el => { this.messagesEnd = el; }}></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  if (!state.user) return {};
  return {
    userID: state.user.uid,
    messages: state.messages,
    botReply: state.botReply
  };
};

export default connect(mapStateToProps)(MessageList);
