// import { write } from "xlsx";
import { firestoreDB } from "../../config/firebase.js";
import { addDoc, getDocs, getDoc, setDoc, collection, orderBy, deleteDoc, writeBatch, doc, updateDoc, where, limit, query,onSnapshot } from "firebase/firestore";
/**
 * The file where we connect our project with the firestore
 * There are utility functions related to firestore here as well
 * The PostQUery is creating a query and submitting that on the behalf of the user
 */
export const postQuery = (path, data) => {
  const collectionRef = collection(firestoreDB, path)
  addDoc(collectionRef, data).then((docRef) => {

  })
    .catch((error) => {
      console.error("Error adding document:", error);
    });
  // firestoreDB
  //   .collection(path)
  //   .add(data)
  //   .then((response) => {
  //     // console.log(response);
  //   })
  //   .catch((err) => console.error(err));
};

/**
 * The loadMessaages is responsible for loading the messages and updating the redux store
 */

const removeOldDocs = async (path, count = 16) => {

  const batch = writeBatch(firestoreDB)
  const queryRef = query(collection(firestoreDB, path),orderBy('timeStamp', 'desc'),limit(50)); // Limit to 50 documents per batch
  const snpshot = await getDocs(queryRef);
  if (snpshot.empty) {
    return;
  }
  try {
    let currDocs = snpshot.docs
    let deleteCount = 0
    for (let i = count; i < currDocs.length; i++) {
      batch.delete(currDocs[i].ref)
      deleteCount++;
      if (deleteCount === 50) break
    }

    return await batch.commit()
  }
  catch (er) {
    console.error("error while deleting previous docs", er);
  }
}


export const loadMessages = (path, callback) => {

  removeOldDocs(path).then(() => {

  })

  const queryRef = query(collection(firestoreDB, path),orderBy('timeStamp', 'desc'),limit(16)) ; // Limit to 50 documents per batch
  onSnapshot(queryRef,(snapshot) => {
    snapshot
      .docChanges()
      .reverse()
      .forEach((change) => {
        let message = change.doc.data()
        if (change.type === 'added') {
          message.changeType = "added"
          callback({ key: change.doc.id, ...message });
        }
        else if (change.type === "removed") {
          if (message.text && message.text.toLowerCase().startsWith("please wait")) {
            message.changeType = "removed"
            callback({ key: change.doc.id, ...message });
          }
        }
      });
  })
};

export const getFirestoreDoc = (collectionPath, docID, callback, onChangeCallBack, errorCallBack = null) => {

  if (onChangeCallBack) {
    onSnapshot(query(collection(firestoreDB, collectionPath)),(changes) => {
      const modifiedDocs = changes.docChanges().filter((change) => change.type === "modified" && change.doc.id === docID);
      if (modifiedDocs.length > 0) onChangeCallBack(modifiedDocs[0].doc.data())
    })
  }
  getDocs(query(collection(firestoreDB, collectionPath))).then((snp) => {
    let targetDoc = null
    snp.forEach((doc) => {
      if (doc.id === docID) {
        targetDoc = doc.data()
      }
    })
    if (targetDoc) callback(targetDoc)
    else throw new Error("Doc Not Found")
  }).catch((err) => {
    if (errorCallBack) {
      errorCallBack(err)
    }
  })
}

/**
 * Takes the name, docId and ata and returns the docId and updates it with the data
 */
export const addFileMetaData = (collectionName, docID, data) => {
  return updateDoc(doc(firestoreDB, collectionName, docID), data)
};

export const addFireStoreDoc = (col, docID, data = {}, callback = () => { }) => {
  //const collectionRef = collection(firestoreDB, col)
  setDoc(doc(firestoreDB, col, docID), data).then(() => {
    callback();
  });


  // const cityRef = firestoreDB.collection(col).doc(docID).set(data, { merge: true }).then(()=>{
  //   callback();
  // });

  // firestoreDB.setDoc
  // return firestoreDB.collection(col).add(docID).then(
  //   (res) => {
  //     console.log("Doc created")
  //   })
  //   .catch((err) => 
  //   console.log(err)
  //   );
};

/**
 * Get all the vendors of a particular
 */
export const getUserVendors = async (uid) => {

  const colRef = collection(firestoreDB, "userVendors"); // Limit to 50 documents per batch

  const queryRef = query(colRef, where("userID", "==", uid))
  return getDocs(queryRef)
    .then((response) => {
      return response.docs.map((doc) => {
        return doc.data();
      });
    })
    .catch((err) => err);
};
/**
 * Get all the Files of a user
 */
// export const getUserFiles = (
//   userID,
//   type,
//   callback,
//   middleware = (files) => { }
// ) => {
//   firestoreDB
//     .collection(`userFiles/${userID}/${type}`)
//     .orderBy("CREATEDAT", "desc")
//     .onSnapshot((changes) => {
//       const onlyAddedChanges = changes
//         .docChanges()
//         .filter((change) => change.type === "added");

//       const addedFiles = onlyAddedChanges.map((change) => {
//         return { docID: change.doc.id, ...change.doc.data() };
//       });

//       if (typeof middleware === "function") middleware(addedFiles);
//       callback(addedFiles);
//     });
// };
/**
 * Updates a document based on the path, docId and the data
 */
export const updateDocument = (path, docID, data, callback = () => { }, errorCallBack = () => { }) => {
  updateDoc(doc(firestoreDB,path, docID), data).then((response) => {
    callback();
  })
    .catch((err) => {
      errorCallBack()
      // console.log("error in update : ",data,err)
    });
};

export const updateLogDocument = (logsCollection, userID, data) => {
  getDoc(doc(firestoreDB,logsCollection, userID)).then((res) => {
    if (res.exists()) {
      updateDocument(logsCollection, userID, data)
    }
    else {
      addFireStoreDoc(logsCollection, userID, data)
    }
  })
}

export const deleteDocument = async (path, docId) => {
  deleteDoc(doc(firestoreDB,path, docId)).then(() => {
    console.log("deleted");
  })
}


export const allDocuments = (path, callback) => {
  getDocs(query(collection(firestoreDB, path))).then((snp) => {
    callback(snp.docs);
  })
}


export const checkDocuments = (path, field, value, displayCallBack, welcomeCallBack) => {
  getDocs(query(collection(firestoreDB, path))).then((snp) => {
    if (snp.docs.length === 0) welcomeCallBack()
    displayCallBack();
  })

  // return docs;
}



// export const deleteDocuments = (path) =>
// {
//   firestoreDB
//     .collection(path).listDocuments().then(val => {
//       val.map((val) => {
//           val.delete()
//       })
//   })

// }