import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import "../../../../../../src/assets/css/EasyQuery.css";
import "../../../../../../src/assets/css/ButtonCss.css";
import { DiningSharp } from "@mui/icons-material";


/**
 * The file where the easy query form is present
 * Returns the modal with a button, which is used for toggling the visibility of the modal form
 * There are 3 options Cancel, Custom and Submit
 * Cancel closes the form
 * Custom puts the query in the textbox but doesn't submit
 * Submit submits the query on the behalf of the user
 *
 * But the problem with this file is that it is too long
 * In future there may be a need to break this file down
 * This file doesn't contain much logic, but only the eventListeners and some stuff
 * This file would be break down when the css is separated
 */
export class EasyQuery extends React.Component {
  initialValues = {
    queryText: "",
    clarity: "",
    shape: "",
    fluor: "",
    pricerange: "",
    weightrange: "",
    multivalue: "",
    gia: "",
    hrd: "",
    igi: "",
    fm: "",
    D: "",
    E: "",
    F: "",
    G: "",
    H: "",
    I: "",
    J: "",
    K: "",
    L: "",
    M: "",
    N: "",
    O: "",
    P: "",
    none: "",
    faint: "",
    strong: "",
    verystrong: "",
    medium: "",
    submitform: false,
    round: "",
    marquise: "",
    princess: "",
    pear: "",
    oval: "",
    heart: "",
    cushionmod: "",
    cushion: "",
    radiant: "",
    ashcher: "",
    fl: "",
    if: "",
    vvs1: "",
    vvs2: "",
    si1: "",
    si2: "",
    vs1: "",
    vs2: "",
    i1: "",
    i2: "",
    i3: "",
    minPrice: 0,
    maxPrice: 0,
  };
  state = {
    show: false,
    toSubmit: false,
    minPrice: NaN,
    maxPrice: NaN,
    flag: false,
    minWeight: NaN,
    maxWeight: NaN,
    minM1: "",
    maxM1: "",
    minRatio: "",
    maxRatio: "",
    minTotal: "",
    maxTotal: "",
    minM2: "",
    maxM2: "",
    minM3: "",
    maxM3: "",
    minDepth: "",
    maxDepth: "",
    spacer: " ",
    querySearch: "Easy Query",
  };

  //set state of visibility to false
  handleClose = () => {
    // console.log("called the cloose button")
    this.setState({
      show: false,
    });
  };
  //set state to true
  handleOpen = () => {
    this.setState({
      show: true,
    });
  };
  /**
   * Calls the addInSearchBox of the Messenger
   */
  handleSubmit = (data) => {
    // upload vendor info to firestore
    // console.log(data);
    this.props.addInSearchBox(
      data,
      this.state.toSubmit,
      this.state.minPrice,
      this.state.maxPrice,
      this.state.minWeight,
      this.state.maxWeight,
      this.state.minM1,
      this.state.maxM1,
      this.state.minM2,
      this.state.maxM2,
      this.state.minM3,
      this.state.maxM3,
      this.state.minTotal,
      this.state.maxTotal,
      this.state.minRatio,
      this.state.maxRatio,
      this.state.minDepth,
      this.state.maxDepth
    );
    this.setState({
      toSubmit: false,
      show: false,
      minPrice: NaN,
      maxPrice: NaN,
      minWeight: NaN,
      maxWeight: NaN,
    });
    this.toggleFlag();
  };
  calledhere = () => {
    // console.log("tosubmit");

    this.setState({
      toSubmit: true,
    });
  };
  //Selected price is when the price is changed using the range options
  selectedPrice = (e) => {
    const priceValues = e.target.value;
    if (priceValues.length === 0) {
      this.setState({
        minPrice: NaN,
        maxPrice: NaN,
      });
    } else {
      const priceArray = priceValues.split(" ");
      this.setState({
        minPrice: parseInt(priceArray[0]),
        maxPrice: parseInt(priceArray[1]),
      });
    }
  };
  //Same as above just for weight
  selectedWeight = (e) => {
    // console.log(e.target.value);
    const weightValues = e.target.value;
    if (weightValues.length === 0) {
      this.setState({
        minWeight: NaN,
        maxWeight: NaN,
      });
    } else {
      const weightArray = weightValues.split(" ");
      // console.log(weightArray);
      this.setState({
        minWeight: parseFloat(weightArray[0]),
        maxWeight: parseFloat(weightArray[1]),
      });
    }
  };
  //Min Price is changed
  minPriceChanged = (e) => {
    this.setState({
      minPrice: e.target.value,
    });
  };
  //Max price is changed
  maxPriceChanged = (e) => {
    this.setState({
      maxPrice: e.target.value,
    });
  };
  //Min weight changed
  minWeightChanged = (e) => {
    this.setState({
      minWeight: e.target.value,
    });
  };
  //Max weight changed
  maxWeightChanged = (e) => {
    this.setState({
      maxWeight: e.target.value,
    });
  };
  //MinM1 changed
  minM1Changed = (e) => {
    this.setState({
      minM1: e.target.value,
    });
  };
  //MaxM1 Changed
  maxM1Changed = (e) => {
    this.setState({
      maxM1: e.target.value,
    });
  };
  minM2Changed = (e) => {
    this.setState({
      minM2: e.target.value,
    });
  };
  //maxM2 Changed
  maxM2Changed = (e) => {
    this.setState({
      maxM2: e.target.value,
    });
  };
  //Min m3 changed
  minM3Changed = (e) => {
    this.setState({
      minM3: e.target.value,
    });
  };
  //Max m3 changed
  maxM3Changed = (e) => {
    this.setState({
      maxM3: e.target.value,
    });
  };
  //Min total changed
  minTotalChanged = (e) => {
    this.setState({
      minTotal: e.target.value,
    });
  };
  //Max total changed
  maxTotalChanged = (e) => {
    this.setState({
      maxTotal: e.target.value,
    });
  };
  //Min ratio changed
  minRatioChanged = (e) => {
    this.setState({
      minRatio: e.target.value,
    });
  };
  //Max ratio changed
  maxRatioChanged = (e) => {
    this.setState({
      maxRatio: e.target.value,
    });
  };
  //Min depth changed
  minDepthChanged = (e) => {
    this.setState({
      minDepth: e.target.value,
    });
  };
  //Max depth Changed
  maxDepthChanged = (e) => {
    this.setState({
      maxDepth: e.target.value,
    });
  };
  /**
   * The function for showing the modal where the css is generated
   * Contains all the required and appropriate data values of the fields of a diamond
   */
  render() {
    return (
      <>
        <Modal className="easyQueryModal" show={this.state.show} onHide={this.handleClose} size="lg">
          <Formik
            initialValues={this.initialValues}
            onSubmit={(data) => {
              // console.log(data);
              // console.log("inside submit button");
              this.handleClose();
              this.handleSubmit(data);
            }}
          >
            <Form>
              <Modal.Body>
                <div className="container easy-query">
                  <div className="row">
                    <div className="col p-2 text-center">
                      <div className="h3">Query Made Easy</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center">
                      <div>
                        <label className="lbl-size"
                          htmlFor="vendor-name"
                        >
                          <b>Shape</b>
                        </label>

                        <label className="lbl-right">
                          <Field type="checkbox" name="round" value="round " /> Round
                        </label>
                        <label className="lbl-right">
                          <Field
                            type="checkbox"
                            name="marquise"
                            value="marquise "
                          /> Marq
                        </label>
                        <label className="lbl-right">
                          <Field
                            type="checkbox"
                            name="princess"
                            value="princess "
                          /> Princess
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="pear" value="oval " /> Oval
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="heart" value="heart " /> Heart
                        </label>
                        <label className="lbl-right">
                          <Field
                            type="checkbox"
                            name="cushion"
                            value="cushion "
                          /> Cushion
                        </label>
                        <label className="lbl-right">
                          <Field
                            type="checkbox"
                            name="cushionmod"
                            value="cushion modified "
                          /> Cushion-mod
                        </label>
                        <label className="lbl-right">
                          <Field
                            type="checkbox"
                            name="ashcher"
                            value="ashcher "
                          /> Ashcher
                        </label>
                        <label className="lbl-right">
                          <Field
                            type="checkbox"
                            name="radiant"
                            value="radiant "
                          /> Radiant
                        </label>
                      </div>


                      <div>
                        <label className="lbl-center"
                          htmlFor="vendor-name"
                        >
                          <b className="mx-3">Carat</b>
                          <Field
                            as="select"
                            name="weightrange"
                            onClick={this.selectedWeight}
                          >
                            <option value="">All</option>
                            <option value="0.18 0.45 ">0.18 - 0.45</option>
                            <option value="0.45 0.95 ">0.45-0.95</option>
                            <option value="0.96 1.45 ">0.96-1.45</option>
                            <option value="1.45 10 ">1.45-10</option>
                          </Field>
                          <input className="inp-center"
                            type="number"
                            onChange={this.minWeightChanged}
                            value={this.state.minWeight}
                            placeholder="Minimum"
                          />
                          <input className="inp-center"
                            type="number"
                            onChange={this.maxWeightChanged}
                            value={this.state.maxWeight}
                            placeholder="Maximum"
                          />
                        </label>
                      </div>
                      {/* clarityRange = ['fl', 'if', 'vvs1', 'vvs2', 'vs1', 'vs2', 'si1', 'si2', 'i1', 'i2', 'i3'] */}


                      <div>


                        <label className="lbl-size"
                          htmlFor="vendor-name"
                        >
                          <b>Color</b>
                        </label>
                        <label className="lbl-right"
                        >
                          <Field type="checkbox" name="D" value="D " /> D
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="E" value="E " /> E
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="F" value="F " /> F
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="G" value="G " /> G
                        </label>
                        <label style={{ marginRight: "10px" }}>
                          <Field type="checkbox" name="H" value="H " /> H
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="I" value="I " /> I
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="J" value="J " /> J
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="K" value="K " /> K
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="L" value="L " /> L
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="M" value="M " /> M
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="N" value="N " /> N
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="O" value="O " /> O
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="P" value="P " /> P
                        </label>
                        {/* Fluoroscence values */}
                        {/* ['none', 'faint', 'medium', 'strong', 'very strong' */}
                      </div>


                      <div>
                        <label className="lbl-size"
                          htmlFor="vendor-name"
                        >
                          <b>Clarity</b>
                        </label>

                        <label className="lbl-right">
                          <Field type="checkbox" name="fl" value="fl " /> Fl
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="if" value="if " /> If
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="vvs1" value="vvs1 " /> VVS1
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="vvs2" value="vvs2 " /> VVS2
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="si1" value="si1 " /> si1
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="si2" value="si2 " /> Si2
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="i1" value="i1 " /> I1
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="i2" value="i2 " /> I2
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="i3" value="i3 " /> I3
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="vs1" value="vs1 " /> VS1
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="vs2" value="vs2 " /> VS2
                        </label>
                      </div>
                      
                      <div>
                        <label className="lbl-size"
                          htmlFor="vendor-name"
                        >
                          <b>Cut Polish Sym</b>
                        </label>
                        <label className="lbl-right">
                          <Field
                            type="radio"
                            name="multivalue"
                            value=" "
                            checked="checked"
                          /> All
                        </label>
                        <label className="lbl-right">
                          <Field type="radio" name="multivalue" value="3x " /> 3X
                        </label>
                        <label className="lbl-right">
                          <Field type="radio" name="multivalue" value="2x " /> 2X
                        </label>
                        <label className="lbl-right" >
                          <Field type="radio" name="multivalue" value="3vg " /> 3VG
                        </label>
                        </div>
                        <div>

                        <label className="lbl-size"
                          htmlFor="vendor-name"
                        >
                          <b>Fluoroscence</b>
                        </label>

                        <label className="lbl-right">
                          <Field type="checkbox" name="none" value="none " /> None
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="faint" value="faint " /> Faint
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="medium" value="medium " /> Medium
                        </label>
                        <label className="lbl-right">
                          <Field type="checkbox" name="strong" value="strong " /> Strong
                        </label>
                        <label className="lbl-right">
                          <Field
                            type="checkbox"
                            name="verystrong"
                            value="very strong "
                          /> Very Strong
                        </label>
                        {/* shapeRange = ["round", "marquise", "princess", "pear", "oval", "heart",
                       "cushion modified", "cushion", "ashcher", "radiant"] */}
                      </div>


                      <div className="priceField">
                        <label className="lbl-size"
                          htmlFor="vendor-name"
                        >
                          <b className="mx-3">Price/Total range($)</b>
                          <Field
                            as="select"
                            value=""
                            name="pricerange"
                            onChange={this.selectedPrice}

                          >
                            <option value="">
                              All
                            </option>
                            <option
                              value="0 10000 "
                            >
                              Upto 10K
                            </option>
                            <option value="10000 20000 ">10K-20K</option>
                            <option value="20000 30000 " >20K-30K</option>
                            <option value="30000 40000 ">30K-40K</option>
                            <option value="40000 50000 ">40K-50K</option>
                            <option value="50000 60000 ">50K-60K</option>
                            <option value="60000 70000 ">60K-70K</option>
                            <option value="70000 80000 ">70K-80K</option>
                            <option value="80000 90000 ">80K-90K</option>
                            <option value="90000 100000 ">90K-100K</option>
                            <option value="100000 10000000 ">Above 100K</option>
                          </Field>

                          <input className="inp-wid20"
                            type="number"
                            onChange={this.minPriceChanged}
                            value={this.state.minPrice}
                            placeholder="Minimum"

                          />
                          <input className="inp-wid20"
                            type="number"
                            onChange={this.maxPriceChanged}
                            value={this.state.maxPrice}
                            placeholder="Maximum"

                          />
                        </label>
                      </div>


                      <div>


                        <label className="lbl-center"
                          htmlFor="vendor-name"

                        >
                          <b>M1 Range&nbsp;&nbsp; </b>
                          <input className="inp-center"
                            type="number"
                            onChange={this.minM1Changed}
                            value={this.state.minM1}
                            placeholder="Min m1"
                          />
                          <input className="inp-center"
                            type="number"
                            onChange={this.maxM1Changed}
                            value={this.state.maxM1}
                            placeholder="Max m1"
                          />
                        </label>
                      </div>


                      <div>
                        <label className="lbl-center"
                          htmlFor="vendor-name"
                        >
                          <b>M2 Range&nbsp;&nbsp; </b>
                          <input className="inp-center"
                            type="number"
                            onChange={this.minM2Changed}
                            value={this.state.minM2}
                            placeholder="Min m2"
                          />
                          <input className="inp-center"
                            type="number"
                            onChange={this.maxM2Changed}
                            value={this.state.maxM2}
                            placeholder="Max m2"
                          />
                        </label>
                      </div>


                      <div>


                        <label className="lbl-center"
                          htmlFor="vendor-name"
                        >
                          <b>M3 Range&nbsp;&nbsp; </b>
                          <input className="inp-center"
                            type="number"
                            onChange={this.minM3Changed}
                            value={this.state.minM3}
                            placeholder="Min m3"
                          />
                          <input className="inp-center"
                            type="number"
                            onChange={this.maxM3Changed}
                            value={this.state.maxM3}
                            placeholder="Max m3"
                          />
                        </label>
                      </div>


                      {/* <label className="lbl-center"
                        htmlFor="vendor-name"
                      >
                        <b>Total Range&nbsp; </b>
                        <input className="inp-center"
                          type="number"
                          onChange={this.minTotalChanged}
                          value={this.state.minTotal}
                          placeholder="Min total"
                        />
                        <input className="inp-center"
                          type="number"
                          onChange={this.maxTotalChanged}
                          value={this.state.maxTotal}
                          placeholder="Max total"
                        />
                      </label>
                      
                       */}
                      <div>


                        <label className="lbl-center"
                          htmlFor="vendor-name"
                        >
                          <b>Ratio Range&nbsp; </b>
                          <input className="inp-center"
                            type="number"
                            onChange={this.minRatioChanged}
                            value={this.state.minRatio}
                            placeholder="Min ratio"
                          />
                          <input className="inp-center"
                            type="number"
                            onChange={this.maxRatioChanged}
                            value={this.state.maxRatio}
                            placeholder="Max ratio"
                          />
                        </label>
                      </div>


                      <div>
                        <label
                          htmlFor="vendor-name"
                          style={{ alignItems: "center" }}
                        >
                          <b>Depth Range&nbsp; </b>
                          <input className="inp-center"
                            type="number"
                            onChange={this.minDepthChanged}
                            value={this.state.minDepth}
                            placeholder="Min depth"
                          />
                          <input className="inp-center"
                            type="number"
                            onChange={this.maxDepthChanged}
                            value={this.state.maxDepth}
                            placeholder="Max depth"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn button-easy-query"
                  type="submit"
                  value="custom"
                >
                  Custom
                </button>
                <button
                  className="btn button-easy-query"
                  type="submit"
                  onClick={this.calledhere}
                  value="submit"
                >
                  Submit
                </button>
                <button
                  className="btn button-easy-query"
                  type="button"
                  onClick={this.handleClose}
                >
                  Cancel
                </button>
              </Modal.Footer>
            </Form>
          </Formik>
        </Modal>
        <button
          type="button"
          onClick={this.handleOpen}
          // className="btn btn-default btn-md button"
          className="btn button-easy-query"
          disabled={!this.props.isAllowed}
        >
          Easy Query
          <div className="button__horizontal"></div>
          <div className="button__vertical"></div>
        </button>
      </>
    );
  }
}
