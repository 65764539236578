import React from "react";
import { MDBCard } from "mdbreact";
import { connect } from "react-redux";
import { queryGenerator } from "../../../../utils/MessengerUtils";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CancelIcon from '@mui/icons-material/Cancel';
import "../../../../assets/css/Messenger.css";
import MessageList from "./MessengerChildren/MessageList";
import { postMessage } from "../../../../actions";
import { updateDocument, addFireStoreDoc, getFirestoreDoc } from "../../../util/firebase-firestore";
import { listBucketObjects, doesExist } from "../../../util/firebase-storage";
import { sendMessage } from "../../../util/util";
import { EasyQuery } from "./MessengerChildren/EasyQuery";
import { TextBox } from "./MessengerChildren/TextBox";
import { SubmitQuery } from "./MessengerChildren/SubmitQuery";
import Tooltip from '@mui/material/Tooltip';
import { firebaseConfig} from '../../../../config/firebase';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import createNotification from "../../../util/Notification";


import { getDatesApi, summaryBucket, masterDfFilePath, masterIndexFilePath, masterColumns, otherFileTypeColumns } from "../../../../config/projectconfigs"
import LoadingSpinner from "../../../util/LoadingSpinner";
import { BarChart, BarPlot, ResponsiveChartContainer, ChartsXAxis } from "@mui/x-charts";




class Messenger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryText: "",
      isAllowed: false,
      placeHolderText: 'Enter Query here..',
      selectedResponseId: null,
      // bannerText: null
      bannerText: null,
      fileDates: {
        "userFileMessages": null,
        "vendorFileMessages": null
      },
      fileDate: null,
      uploadedFiles: null,
      dialogOpen: false,
      cursorActive: false,
      graphText: null,
      priceSeries: null,
      discountSeries: null,
      selectedGraphEntity: null,
      showPreferenceForm: false,
      currColumnPreference: null,
      freezeScroll: false,
      isMobileScreen: window.innerWidth < 1000,
    }
  }



  getHistBins(data) {
    let bins = []
    data.forEach((bin) => {
      if (bin.length == 3) {
        bins.push({
          start: bin[0],
          end: bin[1],
          count: bin[2]
        })
      }
    })

    return bins;
  }

  setPriceSeries = (newPriceSeries, newDiscountSeries, newGraphText, newSelectedGraphEntity = "price per carat") => {
    let queryInput = document.getElementById("queryTextInput")
    this.setState({ priceSeries: newPriceSeries, discountSeries: newDiscountSeries, graphText: newGraphText, selectedGraphEntity: newSelectedGraphEntity, freezeScroll: true }, () => {
      // if (queryInput) {
      //   queryInput.focus()
      // }
    })
  }

  handleGraphEntity = (e) => {
    let queryInput = document.getElementById("queryTextInput")

    this.setState({ selectedGraphEntity: e.target.value }, () => {
      // if (queryInput) {
      //   queryInput.focus()
      // }
    })
  }

  renderPriceHist = () => {
    let graphDataSeries = {
      "price per carat": this.state.priceSeries,
      "discount": this.state.discountSeries
    }
    let currGraphText = this.state.graphText
    if ((graphDataSeries["price per carat"] || graphDataSeries["discount"]) && currGraphText) {
      let graphSelector = <>
        <FormControl className="graphEntitySelector" size="small">
          <InputLabel id="demo-select-small-label">Property </InputLabel>
          <Tooltip arrow title="Select Entity to plot graph" placement="top">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={this.state.selectedGraphEntity}
              label="Source"
              onChange={this.handleGraphEntity}
              defaultValue={this.state.selectedGraphEntity}
            >
              <MenuItem value="price per carat">Price Per Carat ($)</MenuItem>
              <MenuItem value="discount">Discount (%)</MenuItem>
            </Select>
          </Tooltip>
        </FormControl>
      </>
      if (graphDataSeries[this.state.selectedGraphEntity].length) {
        let bins = this.getHistBins(graphDataSeries[this.state.selectedGraphEntity])
        let xAxisData = Array(bins.length).fill(0).map((_, ind) => { return `$${bins[ind].end}`; })
        let seriesDataSet = [{
          type: "bar",
          label: "Price Per Carat",
          data: bins.map((b) => { return b.count }),
          valueFormatter: (count, context) => {
            const indx = context.dataIndex
            return `$${bins[indx].start}-$${bins[indx].end} : ${count}`
          }
        }];
        if (this.state.selectedGraphEntity == "discount") {
          xAxisData = Array(bins.length).fill(0).map((_, ind) => { return `${bins[ind].end}%`; })
          seriesDataSet = [{
            type: "bar",
            label: "Discount",
            data: bins.map((b) => { return b.count }),
            valueFormatter: (count, context) => {
              const indx = context.dataIndex
              return `${bins[indx].start}%-${bins[indx].end}% : ${count}`
            }
          }];
        }

        return (<>
          <div className="graphWrapper">
            <div onClick={(e) => { this.setPriceSeries(null, null, null, null); }} className="closeIcon"><CancelIcon /></div>
            {currGraphText}
            {graphSelector}
            <div className="graphComp">
              {/* <ResponsiveChartContainer 
                series={seriesDataSet}
                xAxis={[{ label:seriesDataSet[0].label,id: 'x-axis-id', scaleType: "band", data: xAxisData, tickLabelPlacement: "tick", tickPlacement: "end",colorMap:{type: 'ordinal', colors: ['#4285f4']} }]}
                yAxis={[{ label: "Count" }]}
                disableAxisListener={true}                
                height={400}
              >
                <BarPlot  />
                <ChartsXAxis label={seriesDataSet[0].label} position="bottom" axisId="x-axis-id" />
              </ResponsiveChartContainer> */}

              <BarChart

                series={seriesDataSet}
                width={700}
                height={400}
                xAxis={[{ label: seriesDataSet[0].label, scaleType: "band", data: xAxisData, tickLabelPlacement: "tick", tickPlacement: "end", colorMap: { type: 'ordinal', colors: ['#4285f4'] } }]}
                yAxis={[{ label: "Count" }]}
                disableAxisListener={true}
                slotProps={{ legend: { hidden: true } }}
              // skipAnimation={true}
              />


            </div>

          </div>
        </>)
      }
      else {
        return <></>
      }
    }
    else {
      return <></>
    }
  }

  setNewPreference = (currentFileType = this.props.fileType) => {
    
    if(currentFileType === "userFileMessages" || currentFileType === "vendorFileMessages"){ 
      addFireStoreDoc(`chats/${this.props.user.uid}/otherFilePreference/`, "tablePreference", { "data": JSON.stringify(otherFileTypeColumns) });
      this.changePreferenceFormVisibility(false, otherFileTypeColumns["otherFileTypeColumns"])
    }
    else{
      addFireStoreDoc(`chats/${this.props.user.uid}/masterFilePreference/`, "tablePreference", { "data": JSON.stringify(masterColumns) });
      this.changePreferenceFormVisibility(false, masterColumns["masterColumns"])
    }
  }

  savePreference = () => {
    if(["userFileMessages", "vendorFileMessages"].includes(this.props.fileType)){
      updateDocument(`chats/${this.props.user.uid}/otherFilePreference/`, "tablePreference", { "data": JSON.stringify({ "otherFileTypeColumns": this.state.currColumnPreference }) }, () => {
        createNotification('Other File Type Preference Updated Successfully', 'success')
      }, () => {
        createNotification('Other File Type Preference Update Failed', 'error')
      });
    }
    else{
      updateDocument(`chats/${this.props.user.uid}/masterFilePreference/`, "tablePreference", { "data": JSON.stringify({ "masterColumns": this.state.currColumnPreference }) }, () => {
        createNotification('Master File Preference Updated Successfully', 'success')
      }, () => {
        createNotification('Master File Preference Update Failed', 'error')
      });
    }
  }

  
  fetchPreference = async (currentFileType = this.props.fileType) => {
    const fileType = currentFileType;
    const path = `chats/${this.props.user.uid}/${fileType === 'userFileMessages' || fileType === 'vendorFileMessages' ? 'otherFilePreference' : 'masterFilePreference'}`;
    const preferenceKey = fileType === 'userFileMessages' || fileType === 'vendorFileMessages' ? 'otherFileTypeColumns' : 'masterColumns';
  
    try {
      const response = await getFirestoreDoc(path, "tablePreference");
      if (response && response.exists) {
        const preferenceData = JSON.parse(response.data())[preferenceKey];
        if (preferenceData) {
          this.changePreferenceFormVisibility(false, preferenceData);
        } else {
          this.setNewPreference(currentFileType);
        }
      } else {
        this.setNewPreference(currentFileType);
      }
    } catch (error) {
      console.error("Error fetching preference:", error);
      this.setNewPreference(currentFileType); // Set default preference on error
    }
  };
  

  // fetchPreference = () => {
  //   if(this.props.fileType == "userFileMessages" || this.props.fileType == "vendorFileMessages"){
  //     try{
  //       getFirestoreDoc(`chats/${this.props.user.uid}/otherFilePreference/`, "tablePreference", (res) => {
  //         if (res.exists()) {
  //           let preferenceData = JSON.parse(res["data"])["otherFileTypeColumns"]
  
  //           if (!preferenceData) {
  //             this.setNewPreference()
  //           }
  //           else {
  //             this.changePreferenceFormVisibility(false, preferenceData)
  //           }
  //         }
  //         else {
  //           this.setNewPreference()
  //         }
  //       },null,(er)=>{
  //         this.setNewPreference()
  //       })
  //     }
  //     catch(err){
  //       this.setNewPreference()
  //     }
  //   }   
  //   else{
  //     try {
  //       getFirestoreDoc(`chats/${this.props.user.uid}/masterFilePreference/`, "tablePreference", (res) => {
  //         if (res.exists()) {
  //           let preferenceData = JSON.parse(res["data"])["masterColumns"]
  
  //           if (!preferenceData) {
  //             this.setNewPreference()
  //           }
  //           else {
  //             this.changePreferenceFormVisibility(false, preferenceData)
  //           }
  //         }
  //         else {
  //           this.setNewPreference()
  //         }
  //       },null,(er)=>{
  //         this.setNewPreference()
  //       })
  //     }
  //     catch (err) {
  //       this.setNewPreference()
  //     }
  //   }   
     // firestoreDB.collection(`chats/${this.props.user.uid}/masterFilePreference/`).doc("tablePreference").get()
    //   .then((res) => {
    //     let preferenceData = JSON.parse(res.data()["data"])["masterColumns"]

    //     if (!preferenceData) {
    //       throw new Error("Data not Found")
    //     }
    //     else {
    //       this.changePreferenceFormVisibility(false, preferenceData)
    //     }
    //   }).catch((err) => {
    //     addFireStoreDoc(`chats/${this.props.user.uid}/masterFilePreference/`, "tablePreference", { "data": JSON.stringify(masterColumns) });
    //     this.changePreferenceFormVisibility(false, masterColumns)
    //   })
//  }

  changePreferenceFormVisibility = (newVal, currColumnPreference = null) => {
    this.setState({ showPreferenceForm: newVal })
    if (currColumnPreference) {
      this.setState({ currColumnPreference: currColumnPreference })
    }
  }


  renderMasterPreference = () => {
    if (this.state.showPreferenceForm && this.state.currColumnPreference) {
      let currColumnPreference = this.state.currColumnPreference
      let prefContainerRef = React.createRef();
      let reorderMasterColumns = (result) => {
        try {
          const startIndex = result.source.index;
          const endIndex = result.destination.index;
          let tempEle = currColumnPreference[startIndex]
          currColumnPreference = [...currColumnPreference.slice(0, startIndex), ...currColumnPreference.slice(startIndex + 1)]
          currColumnPreference.splice(endIndex, 0, tempEle)
          this.setState({ currColumnPreference: currColumnPreference })
        } catch (error) {
        }
      }

      let changeColumnVisibility = (columnIndx) => {
        try {
          let currVal = currColumnPreference[columnIndx][1]
          currColumnPreference[columnIndx][1] = 1 - currVal
          this.setState({ currColumnPreference: currColumnPreference })

        } catch (error) {
        }
      }

      let applyToAll = (newStatus) => {
        for (let i in currColumnPreference) {
          currColumnPreference[i][1] = newStatus
        }
      }


      return (<>
        <Dialog className="prefenrenceWrapper" onClose={(e) => { this.changePreferenceFormVisibility(false) }} open={this.state.showPreferenceForm}>
          <IconButton
            aria-label="close"
            onClick={(e) => { this.changePreferenceFormVisibility(false) }}
            sx={{
              position: 'absolute',
              right: 4,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="preferenceForm">
            <div className="columnPreference">
              <h3>
                Column Order
              </h3>
              <div ref={prefContainerRef} className="columnsWrapper">
                <DragDropContext onDragEnd={reorderMasterColumns}>
                  <Droppable
                    droppableId="droppable"
                    type="CHIP"
                  >
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {Object.values(currColumnPreference).map(([colName, visb], indx) => {
                          return (
                            <Draggable key={colName} index={indx} draggableId={colName}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`${visb == 1 ? "" : "hiddenCol"}`}
                                >
                                  {colName}
                                  <div className="editColumnIcon" >
                                    <Tooltip title={visb == 1 ? "Hide Column" : "Show Column"}>
                                        <IconButton indx={indx} onClick={(e) => { changeColumnVisibility(indx); }}>
                                          {visb == 1 ? (<RemoveCircleIcon />) : (<AddCircleIcon />)}
                                        </IconButton>
                                      </Tooltip>

                                  </div>

                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            <Button className="saveButton" startIcon={<SaveIcon />} variant="contained" onClick={(e) => { this.savePreference() }} >Save Preference</Button>
          </div>
        </Dialog>
      </>)
    }
    else {
      return <></>
    }
  }



  // cursorActive = false

  toggleCursor = () => {
    let newState = !this.state.cursorActive
    this.setState({ cursorActive: newState })
    // console.log(newState);
  }

  setResponse = (resId, bannerText) => {
    this.setState({ selectedResponseId: resId, bannerText: bannerText })
    //  console.log("Selected Response ",resId);
  }

  handleMasterFileAvailibility = (fileType = this.props.fileType) => {

    if (fileType == "masterFileMessages") {
      this.isRapnetFilesAvailable().then((isAvailable) => {
        if (isAvailable) {
          this.setState({ isAllowed: true, placeHolderText: "Enter Query here.." })
        }
        else {
          this.setState({
            isAllowed: false,
            placeHolderText: "Rapnet File Not Available currently"
          })
        }
      })
    }

  }

  setFreezeScroll = (newVal) => {
    this.setState({ freezeScroll: newVal })
  }

  handleSource = (event) => {
    this.props.setFileType(event.target.value)
    this.fetchPreference(event.target.value);
    if (event.target.value != "masterFileMessages") {
      this.fetchFileDates(event.target.value, (fileType, defaultVal) => {
        this.fetchUploadedFiles(fileType, defaultVal, () => {
        })
      })
    }
    else {
      this.handleMasterFileAvailibility(event.target.value)
    }
    this.setState({ bannerText: null, selectedResponseId: null, placeHolderText: 'Enter Query here..', freezeScroll: false })
  }

  handleQuerySubmit = (event) => {
    event.preventDefault();

    if (this.state.queryText.length === 0) return;
    else {
      const { uid } = this.props.user;
      let curr_queryText = this.state.queryText
      // console.log(this.state.queryText);
      this.setState({ queryText: "", freezeScroll: false })

      let textAreaEle = document.getElementsByClassName("text-area")
      if (textAreaEle.length > 0) {
        textAreaEle = textAreaEle[0]
        textAreaEle.value = ''
      }
      let fileNames = this.state.uploadedFiles.filter((file) => { return file[0] }).map((file) => { return file.slice(1).join("/") })
      sendMessage(uid, false, curr_queryText, "desktop", this.props.fileType, this.state.fileDate, fileNames, this.state.selectedResponseId);
    }

  };
  /**
   * Set the text to keep track of the latest value
   */
  onInputChange = (event) => {
    this.setState({
      queryText: event.target.value,
    });
  };

  renderthis = () => {
    return (
      <textarea
        spellcheck="false"
        style={{ width: 300, height: 100, display: "block" }}
      ></textarea>
    );
  };

  toggleFlag = () => {
    this.setState({
      flag: !this.state.flag,
    });
  };

  /**
   * Responsible for adding the query in the search box which is created by the modal
   * uses utility functions which are in the utils folder so as to separate logic and frontend
   */
  addInSearchBox = (
    data,
    checkSubmit,
    minPrice,
    maxPrice,
    minWeight,
    maxWeight,
    minM1,
    maxM1,
    minM2,
    maxM2,
    minM3,
    maxM3,
    minTotal,
    maxTotal,
    minRatio,
    maxRatio,
    minDepth,
    maxDepth
  ) => {
    const queryCreated = queryGenerator(
      data,
      checkSubmit,
      minPrice,
      maxPrice,
      minWeight,
      maxWeight,
      minM1,
      maxM1,
      minM2,
      maxM2,
      minM3,
      maxM3,
      minTotal,
      maxTotal,
      minRatio,
      maxRatio,
      minDepth,
      maxDepth
    );
    this.setState({
      queryText: queryCreated,
    });

    if (checkSubmit) {
      if (this.state.queryText.length === 0 || this.state.queryText === "")
        return;
      const { uid, photoURL } = this.props.user;


      // console.log(queryCreated);

      let fileNames = this.state.uploadedFiles.filter((file) => { return file[0] }).map((file) => { return file.slice(1).join("/") })
      sendMessage(uid, false, this.state.queryText, "desktop", this.props.fileType, this.state.fileDate, fileNames, this.state.selectedResponseId);

      this.setState({
        queryText: "",
        freezeScroll: false
      });
      this.toggleFlag();
    }
  };
  /**
   * Trigger on enter key
   * when the key is enter, and the text is not null
   * submit a query and reset the text in the textbox
   */
  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (this.state.queryText.length === 0 || this.state.queryText === "")
        return;
      const { uid } = this.props.user;

      let fileNames = this.state.uploadedFiles.filter((file) => { return file[0] }).map((file) => { return file.slice(1).join("/") })
      sendMessage(uid, false, this.state.queryText, "desktop", this.props.fileType, this.state.fileDate, fileNames, this.state.selectedResponseId);

      this.setState({
        queryText: "",
        freezeScroll: false
      });
      this.toggleFlag();
    }
  };

  appendQuery = (e, clearFirst = false) => {
    let currQueryText = this.state.queryText
    if (!currQueryText || clearFirst) currQueryText = ""
    let newText = e.target.getAttribute("value").toString()
    if (newText.length > 0) {
      currQueryText = currQueryText + " " + newText
      this.setState({ queryText: currQueryText })
      document.getElementById("queryTextInput").focus()
    }

  }


  fetchFileDates = async (userType, callback, forceUpdate = false) => {
    let isUser;
    if (!forceUpdate && this.state.fileDates[userType]) {
      let dateList = this.state.fileDates[userType]
      let defaultVal = dateList.length ? dateList.slice(-1)[0] : null
      this.setState({ fileDate: defaultVal })
      if (callback) callback(userType, defaultVal)
      return
    }
    if (userType == "vendorFileMessages") isUser = "False"
    else isUser = "True"

    const { uid } = this.props.user;

    let tempFileDates = this.state.fileDates
    const payload = { "user_id": uid, "isUser": isUser, "projectId": firebaseConfig["projectId"] }
    try {
      axios.defaults.withCredentials = false
      axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
      axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

      axios.get(getDatesApi, { params: payload }, {
        crossDomain: true
      }).then(response => {
        let defaultVal = null

        if (response.status == 201) {
          tempFileDates[userType] = []
        }
        else {
          let dateList = response.data.files
          tempFileDates[userType] = dateList
          defaultVal = dateList.length ? dateList.slice(-1)[0] : null
        }
        this.setState({ fileDate: defaultVal })
        this.setState({ fileDates: tempFileDates })

        if (callback) callback(userType, defaultVal)
      })
        .catch(error => {
          // console.log("Error Fetching latest dates :", error);
          tempFileDates[userType] = []
          this.setState({ fileDates: tempFileDates })
          if (callback) callback(userType, null)
        });
    } catch (error) {
      // console.log("Error Fetching latest dates :", error);
      tempFileDates[userType] = []
      this.setState({ fileDates: tempFileDates })
      if (callback) callback(userType, null)
    }
  }

  handleFileDate = (e) => {
    this.setState({ fileDate: e.target.value, uploadedFiles: null })
    this.fetchUploadedFiles(this.props.fileType, e.target.value, () => { })
  }

  renderFileDates = (fileDates) => {
    return fileDates.map((date, index) => (
      <MenuItem key={index} value={date}>{date}</MenuItem>
    ));
  }

  renderDateSelection = () => {
  if (this.props.fileType === "masterFileMessages") return <></>;

  let fileDates = this.state.fileDates[this.props.fileType];
  if (fileDates.length > 0) {
    return (
      <>
        <FormControl className="fileDateSelector" sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Date</InputLabel>
          <Tooltip arrow title="Select Date of uploaded file" placement="top">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={this.state.fileDate}
              label="Source"
              onChange={this.handleFileDate}
              defaultValue={this.state.fileDate}
            >
              {this.renderFileDates(fileDates)}
            </Select>
          </Tooltip>
        </FormControl>
      </>
    );
  } else {
    return <>No Uploaded Files</>;
  }
}


  fetchUploadedFiles = (fileType, fileUploadDate, callback) => {
    if (!fileUploadDate) {
      this.setState({ uploadedFiles: [] })
      if (callback) callback()
    }
    else {
      fileUploadDate = fileUploadDate.replaceAll(/[/ \-]/g, "")
      let filePath = `${summaryBucket}/${this.props.user.uid}/${fileUploadDate}/${(fileType == "userFileMessages") ? 'User_files' : 'Vendor_files'}/`


      // getFiles(filePath).then((res)=>{
      //   console.log(res.items.map((file)=>{return file.name}));
      // })

      listBucketObjects(filePath).then((res) => {

        if (fileType == "userFileMessages") {
          let uploadedFiles = res.items.map((itemRef) => {
            return [1, itemRef.name.replace("_output", "")];
          });
          this.setState({ uploadedFiles: uploadedFiles })
          if (callback) callback()
        }
        else if (fileType == "vendorFileMessages") {
          let uploadedFiles = []
          res.prefixes.forEach((folderRef, idx, arr) => {
            listBucketObjects(filePath + folderRef.name + "/").then((resFiles) => {
              let vendorFiles = resFiles.items.map((itemRef) => {
                return [1, folderRef.name, itemRef.name.replace("_output", "")]
              })
              uploadedFiles.push(...vendorFiles)

              this.setState({ uploadedFiles: uploadedFiles })
              if (callback) callback()
              // console.log(uploadedFiles);
            })
          })
        }
      })
    }

  }

  toggleFileSelect = (index) => {
    let tempFileData = this.state.uploadedFiles;
    tempFileData[index][0] = (tempFileData[index][0] + 1) % 2
    this.setState({ uploadedFiles: tempFileData })
    // console.log(this.state.uploadedFiles);
  }

  renderUploadedFiles = () => {
  return (
    <>
      <div className="uploadedFilesDiv">
        {this.state.uploadedFiles.length > 0 ? (
          <>
            <span>Selected Files</span>
            {this.state.uploadedFiles.map((file, index) => {
              let fileSelectStatus = file[0];
              let fileName = file.slice(1).join(" : ");
              return (
                <div
                  key={index} // Add key prop here
                  onClick={() => {
                    this.toggleFileSelect(index);
                  }}
                  className="uploadedFileWrapper"
                >
                  <div className="uploadedFile">
                    <button>
                      <i className={`${fileSelectStatus ? 'fa-solid' : 'fa-regular'} fa-circle-check`}></i>
                    </button>
                    <span>
                      <span>{fileName}</span>
                    </span>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};


  renderSelectSource = () => {
    return (<>
      <div className="selectSource">
        <FormControl className="fileTypeSelector" sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Source</InputLabel>
          <Tooltip arrow title="Select File Type for which query is executed" placement="top">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={this.props.fileType}
              label="Source"
              onChange={this.handleSource}
              defaultValue="userFileMessages"
            >
              <MenuItem value="userFileMessages">My Company</MenuItem>
              <MenuItem value="vendorFileMessages">Supplier Data</MenuItem>
              <MenuItem value="masterFileMessages">Market Data</MenuItem>
            </Select>
          </Tooltip>
        </FormControl>
        {/* {(this.props.fileType != "masterFileMessages" && this.state.fileDates[this.props.fileType]) ? (<>
          {this.renderDateSelection()}
          {(this.state.uploadedFiles) ?
            (<>
              {this.renderUploadedFiles()}
            </>) :
            (<>
              <CircularProgress className="fileDatesLoader" />
            </>)}
        </>) : (<></>)} */}
      {(["userFileMessages", "vendorFileMessages"].includes(this.props.fileType)) ?
          (this.state.fileDates[this.props.fileType]) ?
            (<>
              {this.renderDateSelection()}
              {(this.state.uploadedFiles) ?
                (<>
                  {this.renderUploadedFiles()}
                </>) :
                (<>
                  <CircularProgress className="fileDatesLoader" />
                </>)}
              <div className="preferenceDiv">
                <Button onClick={(e) => { this.changePreferenceFormVisibility(true) }} variant="outlined">Edit Columns</Button>
              </div>
            </>) :
            (<>
              <CircularProgress className="fileDatesLoader" />
            </>)
          : (<>
            <div className="preferenceDiv">
              <Button onClick={(e) => { this.changePreferenceFormVisibility(true) }} variant="outlined">Edit Columns</Button>
            </div>
          </>)}
      </div>
    </>
  );
};


  isRapnetFilesAvailable = async () => {
    return await doesExist(masterDfFilePath) && await doesExist(masterIndexFilePath)
  }

  handleDialogOpen = () => {
    this.setState({ dialogOpen: true })
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false })
  }
  componentDidMount() {
    if (this.props.fileType !== "masterFileMessages") {
      this.fetchFileDates(this.props.fileType, (fileType, defaultVal) => {
        this.fetchUploadedFiles(fileType, defaultVal, () => {});
      });
    } else {
      this.handleMasterFileAvailibility();
    }
    this.fetchPreference(this.props.fileType);
    this.props.setShowTut(false); // Example of state update in componentDidMount
  }

  componentDidUpdate(prevProps) {
    if (prevProps.someProp !== this.props.someProp) {
      // Example of conditionally updating state based on prop change
      this.props.setShowTut(false);
    }
  }

  render() {
    let isMobileScreen = window.innerWidth < 1000;
    let fileNames = null;
    if (this.state.uploadedFiles) {
      fileNames = this.state.uploadedFiles
        .filter((file) => file[0])
        .map((file) => file.slice(1).join("/"));
    }

    let selectedFiles = [];
    if (this.props.fileType !== "masterFileMessages" && this.state.uploadedFiles) {
      selectedFiles = this.state.uploadedFiles.filter((file) => file[0]);
      if (selectedFiles.length > 0) {
        if (!this.state.isAllowed) {
          this.setState({ isAllowed: true, placeHolderText: "Enter Query here.." });
        }
      } else {
        if (this.state.isAllowed) {
          this.setState({
            isAllowed: false,
            placeHolderText: "Please Select At least One File from list",
          });
        }
      }
    }

    return (
      <div className="messengerDiv position-relative mt-4">
        {isMobileScreen ? (
          <>
            <Button className="selectSourceButton" variant="outlined" onClick={this.handleDialogOpen}>
              Select Source
            </Button>
            <Dialog onClose={this.handleDialogClose} open={this.state.dialogOpen}>
              <IconButton
                aria-label="close"
                onClick={this.handleDialogClose}
                sx={{
                  position: "absolute",
                  right: 4,
                  top: 4,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle>Select Source for Query</DialogTitle>
              {this.renderSelectSource()}
            </Dialog>
          </>
        ) : (
          <>
            {this.renderSelectSource()}
          </>
        )}

        <div className="querybotDiv row d-flex justify-content-center">
          <div className="pos-relative col-12 col-md-10 col-lg-12 col-sm-10">
            {this.renderPriceHist()}
            {this.renderMasterPreference()}
            <MDBCard className="mdb-card">
              <MessageList
                setFreezeScroll={this.setFreezeScroll}
                freezeScroll={this.state.freezeScroll}
                currResponseId={this.state.selectedResponseId}
                columnPreference={this.state.currColumnPreference}
                appendQuery={this.appendQuery}
                setResponse={this.setResponse}
                setPriceSeries={this.setPriceSeries}
                fileType={this.props.fileType}
                cursorActive={this.state.cursorActive}
                fileDate={this.state.fileDate}
                fileNames={fileNames}
              />

              <div className="container align-bottom pos-absolute">
                <form onSubmit={this.handleQuerySubmit}>
                  <div className="queryInput row bottom-bar" style={{ width: "100%" }}>
                    <div className="col-10 ali-cen">
                      {this.state.bannerText ? (
                        <div className="bannerDiv">
                          <div onClick={(e) => { this.setResponse(null, null); }} className="closeIcon">
                            <CancelIcon />
                          </div>
                          <div className="bannerText my-3">{this.state.bannerText}</div>
                          <TextBox
                            queryText={this.state.queryText}
                            onInputChange={this.onInputChange}
                            handleKeyDown={this.handleKeyDown}
                            flag={this.state.flag}
                            isAllowed={this.state.isAllowed}
                            placeholder={this.state.placeHolderText}
                            onFocus={this.toggleCursor}
                            onBlur={this.toggleCursor}
                          />
                        </div>
                      ) : (
                        <TextBox
                          className="bannerTextarea"
                          onFocus={this.toggleCursor}
                          onBlur={this.toggleCursor}
                          queryText={this.state.queryText}
                          onInputChange={this.onInputChange}
                          handleKeyDown={this.handleKeyDown}
                          flag={this.state.flag}
                          isAllowed={this.state.isAllowed}
                          placeholder={this.state.placeHolderText}
                        />
                      )}
                    </div>
                    <div className="col-2 d-flex justify-content-center">
                      <EasyQuery
                        currResponseId={this.state.selectedResponseId}
                        isAllowed={this.state.isAllowed}
                        addInSearchBox={this.addInSearchBox}
                      />
                      <SubmitQuery isAllowed={this.state.isAllowed} />
                    </div>
                  </div>
                </form>
              </div>
            </MDBCard>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  if (!state.user) return {};

  return {
    user: state.user,
    messages: state.messages
  };
};

export default connect(mapStateToProps, { postMessage })(Messenger);