/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DataTable from "./DataTable";
import { auth } from "../../../../../../util/firebase-authentication";
import "../../../../../../../assets/css/SearchResult.css"

import {
//    conditionalDataCreator,
    getColumns,
    getDataRows,
} from "../../../../../../../utils/MessageDetailUtils";
import { columnOrder } from "../../../../../../../config/projectconfigs"

export class SearchResult extends React.Component {
    render() {

        const columns = this.props.columnNames.map(ele => 
            {
                ele = ele.replaceAll(/[#|_|*|-|\s+]/g," ").trim()
                return ele.charAt(0).toUpperCase() + ele.slice(1).toLowerCase()
            });
        const searchResult = this.props.searchResult;

        // let hiddenColumnIndices = this.props.indexHiddenColumnNames
        let hiddenColumnNames = this.props.hiddenColumnNames
        if(hiddenColumnNames)hiddenColumnNames = hiddenColumnNames.map(name => {return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()})

        // let hiddenIndicesCreator = this.props.indexHiddenColumnNames
        //     ? this.props.indexHiddenColumnNames
        //     : null;
        // const conditionalData = conditionalDataCreator(
        //     searchResult,
        //     hiddenIndicesCreator,
        //     columns
        // );

        // let columnSpecs = {"Fluorescent" : "Fluor"}
        // let dataColumns = getColumns(columns,columnSpecs);
        
        let dataColumns = getColumns(columns);


        dataColumns = dataColumns.filter((column) => {
            if (
                column.label.toUpperCase() === "VIDEOFULLLINKS" ||
                column.label.toUpperCase() === "SIZE" ||
                column.label.toUpperCase() === "BRANCH" ||
                column.label.toUpperCase().includes("UNNAMED") || 
                (hiddenColumnNames && hiddenColumnNames.includes(column.label))
            )
                return false;
            return true;
        })

        
        
        if(this.props.columnPreference)
        {
            let currColumnPreference = this.props.columnPreference.map((ele)=>{
                let colName = ele[0]
                colName = colName.replaceAll(/[#|_|*|-|\s+]/g," ").trim()
                colName = colName.charAt(0).toUpperCase() + colName.slice(1).toLowerCase()
                return [colName,ele[1]]
            })

            dataColumns = dataColumns.filter((val)=>{
                const colIndx = currColumnPreference.findIndex(ite => ite[0].toUpperCase()==val.label.toUpperCase())
                if(colIndx!=-1)
                {
                    return currColumnPreference[colIndx][1]==1
                }
                return true
            })
            dataColumns.sort((first, second) => {
                let firstInd = currColumnPreference.findIndex(ite => ite[0].toUpperCase()==first.label.toUpperCase())
                let secondInd = currColumnPreference.findIndex(ite => ite[0].toUpperCase()==second.label.toUpperCase())
                if(firstInd!=-1 && secondInd!=-1)return firstInd - secondInd
                else if (firstInd==-1)return 1
                else if(secondInd==-1)return -1
                else return 0
            })
        }
        else 
        {
            dataColumns.sort((first, second) => {
                let firstInd = columnOrder.indexOf(first.label.toUpperCase())
                let secondInd = columnOrder.indexOf(second.label.toUpperCase())
                if(firstInd!=-1 && secondInd!=-1)return firstInd - secondInd
                else if (firstInd==-1)return 1
                else if(secondInd==-1)return -1
                else return 0
            })
        }

        

        let tableData = []
        

        for (let i in searchResult) {
            let crow = [];
            for (let j = 0; j < searchResult[i].length; j++) {
                crow.push(searchResult[i][j]);
            }
            tableData.push(crow);
          }

        
        const dataRows = getDataRows(tableData, columns);
        if (this.props.fAck) {
            return (
                <div  className="searchDiv" >
                    <DataTable
                   
                        data={{
                            columns: dataColumns,
                            rows: dataRows,
                        }}
                        text = {this.props.text}
                        handleQuerySelect={this.props.handleQuerySelect}
                        appendQuery={this.props.appendQuery}
                    />
                    {this.props.dl_link ? (
                        <span>
                            Please, click{" "}
                            <a
                                href={this.props.dl_link}
                                value={`Download ${this.props.finalPath}`}
                                target="_blank"
                            >
                                here
                            </a>{" "}
                            to Download
                        </span>
                    ) : (
                        <span>
                            Please, click{" "}
                            <a
                                href="#"
                                className="text-primary"
                                value={`Download ${this.props.finalPath}`}
                                onClick={this.props.handleQuerySelect}
                            >
                                here
                            </a>{" "}
                            to generate download link.
                        </span>
                    )}


                    {/* <span>
            Please, click{" "}
            <a
              href="#"
              className="text-primary"
              value={`download ${this.props.finalPath}`}
              onClick={this.props.handleQuerySelect}
            >
              here
            </a>{" "}
            to generate download link.
          </span> */}
                    <br></br>
                    <span>
                        Please, click{" "}
                        <a
                            href="#"
                            className="text-primary"
                            value={`email ${auth.currentUser.email} ${this.props.finalPath}`}
                            onClick={this.props.handleQuerySelect}
                        >
                            here
                        </a>{" "}
                        to get email.
                    </span>
                </div>
            );
        }
        return (
            <DataTable
                data={{
                    columns: dataColumns.filter((item) => {
                        if (
                            item.label === "VideoFullLinks" ||
                            item.label === "Size" ||
                            item.label === "Branch"
                        )
                            return false;
                        return true;
                    }),
                    rows: dataRows,
                }}
                handleQuerySelect={this.props.handleQuerySelect}
                appendQuery={this.props.appendQuery}
            />
        );
    }
}
