// import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";
import "semantic-ui-css/semantic.min.css";
import "react-notifications/lib/notifications.css";
import React from "react";
import reactDom from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import App from "./components/App/App";
import reducers from "./reducers";
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import Temp from './components/App/AppChildren/AppContainerChildren/temp';
// import AddInventory from "./components/App/AppChildren/AppContainerChildren/AddInventory";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/**
 * The store is responsible for the redux states and actions.
 *  Now just by importing the store, we can access any thing we want from the store
 */
const store = createStore(reducers, composeEnhancers(applyMiddleware()));
/**
 *
 * This is the entry point of the whole project, this is where we render the App component
 * This is also responsible for providing the store for the reducers(a common db kind of thing)
 */


// In your main component:

reactDom.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector("#root")
);
