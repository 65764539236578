import { firebaseConfig, firestoreDB } from "./firebase"
import { collection,getDocs,query } from "firebase/firestore";
import { setConfig } from "./setProjectConfig";
/**
 * This file contains all the project related detaills/ name id, fileBucket, master
 * Summary and inventory Bucket
 * So that just by changing these , we can achieve the same things with a different project
 *
 */
const setConfigFlag = false

export const projectId = firebaseConfig["projectId"];


if (setConfigFlag) {
    await setConfig()
}
let colRef = collection(firestoreDB,"paths") 
let pathDocs = await getDocs(query(colRef))
let paths = {}

for(let pathDoc of pathDocs.docs) {
    paths = { ...paths, ...pathDoc.data() }
}

export const fileUploadBucket = paths["inventory-bucket"]
export const summaryBucket = paths["summary-bucket"]
export const chatAppBucket = paths["output-bucket"]

export const masterDfFilePath = paths["master-df-path"].replace("{masterBucket}", chatAppBucket)
export const masterIndexFilePath = paths["master-path"].replace("{masterBucket}", chatAppBucket)



export const jsonFileBucketPath = paths["viewer-file-path"].replace("{outputBucket}", chatAppBucket)

// export const viewerUrl = "https://storage.googleapis.com/" + chatAppBucket + "/viewer/index.html?res_id=";
export const viewerUrl = paths["viewer-path"].replace("{outputBucket}", chatAppBucket)




// export const exportBasePath = "d360-assist-chatapp/Latest/"
// export const exportBasePath = "kp-assist/Latest/"
// export const exportBasePath = projectConfigs[projectId]["destination_bucket_name"] + "/Latest/"
// export const exportUserFileName = "userFile.xlsx"
// export const exportVendorFileName = "vendorFile.xlsx"

export const logsCollection = "logs"

// export const masterBucket = "dinsight-master-files-test";
// export const fileUploadBucket = "assist-dev-inventory-bucket";


// const BASE_API = "https://us-central1-friendlychat-bb9ff.cloudfunctions.net/"
// const BASE_API = "https://us-central1-d360-assist-dev.cloudfunctions.net/"
// const BASE_API = projectConfigs[projectId]["base_api"]

// export const userConvertApi = BASE_API + "convertFile"

// export const userMergeApi = BASE_API + "mergeProcessedUserFiles"


// export const mergeApi = BASE_API + "mergeProcessedFiles"

// export const vendorConvertApi = BASE_API + "convertFile"

// export const vendorMergeApi = BASE_API + "mergeProcessedVendorFiles"

// export const getDatesApi = BASE_API + "GetDates"

// export const makeLatestApi = BASE_API + "updateLatest"

// export const getAvailableNameApi = BASE_API + "getAvailableName"

// export const makeAnalysisApi = BASE_API + "getAnalysisFile"


const BASE_API = paths["base_api"]

export const userConvertApi = paths["convertFile"].replace("{base_api}", BASE_API)
export const vendorConvertApi = paths["convertFile"].replace("{base_api}", BASE_API)
export const getDatesApi = paths["getDates"].replace("{base_api}", BASE_API)
export const getAvailableNameApi = paths["getAvailableName"].replace("{base_api}", BASE_API)



export const mergeApi = BASE_API + "mergeProcessedFiles"
export const userMergeApi = BASE_API + "mergeProcessedUserFiles"
export const vendorMergeApi = BASE_API + "mergeProcessedVendorFiles"
export const makeLatestApi = BASE_API + "updateLatest"
export const makeAnalysisApi = BASE_API + "getAnalysisFile"


//Messenger Configs

export const columnOrder = [
    "SHAPE",
    "CARAT",
    "COLOR",
    "CLARITY",
    "CUT",
    "POLISH",
    "SYMMETRY",
    "FLOUR",
    "RAPRATE",
    "DISCOUNT",
    "RAP PRICE TOTAL",
    "PRICE PER CARAT",
    "TOTAL",
    "MAX PRICE",
    "MIN PRICE",
    "TABLE",
    "LENGTH",
    "WIDTH",
    "DEPTH",
    "RATIO",
    "DEPTH %",
    "VID LINK",
    "LAB LINK",
    "STOCK LINK",
    "DATE",
    "VENDOR",
    "COUNT",
    "REPORT NO",
    "GENERATED REPORT NO"]

// export const masterColumns = {
//     "shape": [0, 1],
//     "carat": [1, 1],
//     "color": [2, 1],
//     "clarity": [3, 1],
//     "cut": [4, 1],
//     "polish": [5, 1],
//     "symmetry": [6, 1],
//     "fluorescent": [7, 1],
//     "raprate": [8, 1],
//     "discount": [9, 1],
//     "rap price total": [10, 1],
//     "price per carat": [11, 1],
//     "total": [12, 1],
//     "depth %": [13, 1],
//     "comments": [14, 1],
//     "report_no": [15, 1],
//     "index": [16, 1],
//     "StockRef": [17, 1],
//     "Table": [18, 1],
//     "Lab": [19, 1],
//     "report_date": [20, 1],
//     "Cert": [21, 1],
//     "DiagramURL": [22, 1],
//     "ImageURL": [23, 1],
//     "VideoURL": [24, 1],
//     "M1": [25, 1],
//     "M2": [26, 1],
//     "M3": [27, 1],
//     "ratio": [28, 1],
//     "Location": [29, 1],
//     "Culet": [30, 1],
//     "Girdle": [31, 1],
//     "Active": [32, 1],
//     "IsLabDiamond": [33, 1],
//     "ModifiedDate": [34, 1],
//     "docID": [35, 1],
//     "CaratRange": [36, 1],
//     "Date": [37, 1],
//     "VendorName": [38, 1],
//     "companyName": [39, 1],
//     "country": [40, 1],
//     "key": [41, 1]
// }

// export const masterColumns = {
//     "masterColumns": [
//       [ 'country', 1 ],
//       [ 'fluorescent', 1 ],
//       [ 'Active', 1 ],
//       [ 'companyName', 1 ],
//       [ 'VendorName', 1 ],
//       [ 'shape', 1 ],
//       [ 'clarity', 1 ],
//       [ 'depth %', 1 ],
//       [ 'ModifiedDate', 1 ],
//       [ 'comments', 1 ],
//       [ 'total', 1 ],
//       [ 'M3', 1 ],
//       [ 'Lab', 1 ],
//       [ 'cut', 1 ],
//       [ 'docID', 1 ],
//       [ 'discount', 1 ],
//       [ 'carat', 1 ],
//       [ 'Date', 1 ],
//       [ 'report_no', 1 ],
//       [ 'symmetry', 1 ],
//       [ 'price per carat', 1 ],
//       [ 'StockRef', 1 ],
//       [ 'index', 1 ],
//       [ 'Table', 1 ],
//       [ 'CaratRange', 1 ],
//       [ 'raprate', 1 ],
//       [ 'report_date', 1 ],
//       [ 'M2', 1 ],
//       [ 'Cert', 1 ],
//       [ 'color', 1 ],
//       [ 'key', 1 ],
//       [ 'IsLabDiamond', 1 ],
//       [ 'DiagramURL', 1 ],
//       [ 'polish', 1 ],
//       [ 'ImageURL', 1 ],
//       [ 'rap price total', 1 ],
//       [ 'Location', 1 ],
//       [ 'VideoURL', 1 ],
//       [ 'M1', 1 ],
//       [ 'Girdle', 1 ],
//       [ 'ratio', 1 ],
//       [ 'Culet', 1 ]
//     ]
//   }

  export const masterColumns = {
    "masterColumns": [
      [ 'ImageURL', 1 ],
	  [ 'shape', 1 ],
	  [ 'carat', 1 ],
	  [ 'color', 1 ],
	  [ 'clarity', 1 ],
	  [ 'cut', 1 ],
	  [ 'polish', 1 ],
	  [ 'symmetry', 1 ],
	  [ 'fluorescent', 1 ],
	  [ 'raprate', 1 ],
	  [ 'discount', 1 ],
      [ 'country', 1 ],
	  [ 'rap price total', 1 ],
	  [ 'price per carat', 1 ],
      [ 'total', 1 ],
	  [ 'Table', 1 ],
      [ 'M1', 1 ],
      [ 'M2', 1 ],
      [ 'M3', 1 ],
      [ 'ratio', 1 ],
      [ 'depth %', 1 ],
      [ 'report_no', 1 ],
      [ 'VideoURL', 1 ],
      [ 'DiagramURL', 1 ],
      [ 'companyName', 1 ],
      [ 'VendorName', 1 ],
      [ 'Active', 1 ],
      [ 'ModifiedDate', 1 ],
      [ 'comments', 1 ],
      [ 'Lab', 1 ],
      [ 'docID', 1 ],
      [ 'Date', 1 ],
      [ 'StockRef', 1 ],
      [ 'index', 1 ],
      [ 'CaratRange', 1 ],      
      [ 'report_date', 1 ],
      [ 'Cert', 1 ],
      [ 'IsLabDiamond', 1 ],
      [ 'Location', 1 ],
      [ 'Girdle', 1 ],
      [ 'Culet',1]
    ]
}

export const otherFileTypeColumns = {
    "otherFileTypeColumns": [
      [ 'shape', 1 ],
      [ 'carat', 1 ],
      [ 'color', 1 ],
      [ 'clarity', 1 ],
      [ 'cut', 1 ],
      [ 'polish', 1 ],
      [ 'symmetry', 1 ],
      [ 'raprate', 1 ],
      [ 'discount', 1 ],
      [ 'country', 1 ],
      [ 'rap price total', 1 ],
      [ 'price per carat', 1 ],
      [ 'total', 1 ],
      [ 'Table', 1 ],
      [ 'M1', 1 ],
      [ 'M2', 1 ],
      [ 'M3', 1 ],
      [ 'ratio', 1 ],
      [ 'depth %', 1 ],
      [ 'report_no', 1 ],
      ['Fluorescent', 1],
    ]
}


export const errorCodes = {
    205: "Error occurred while converting to common format",
    206: "Error Occurred while storing converted file",
    207: "Error Occurred while accessing uploaded files"
}