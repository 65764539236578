import { storage } from "../../config/firebase";
import { ref,listAll,getDownloadURL,uploadBytes } from "firebase/storage";
/**
 * Used by the inventory component for uploading a file to Storage
 */

export const uploadToStorage = async (filepath, file) => {
  const storageRef = ref(storage, `gs://${filepath}`);
  try {
      return uploadBytes(storageRef, file, {
          contentType: file.type,
          cacheControl: "max-age=31536000"
      }).catch((err)=>{
        // console.log(err);
      });
  } catch (err) {
      // console.log("Error uploading file.", err);
  }
}

export const listBucketObjects = (path) => {
  return listAll(ref(storage,`gs://${path}`))
};

export const doesExist = async (path) => {
  try{
    await getDownloadURL(ref(storage,path))
    return true
  }
  catch(err)
  {
    return false
  }
}



export const getFromStorage = (path,fileName) =>{
  return getDownloadURL(ref(storage,`gs://${path}${fileName}`))
}


// export const makePublic = async (path,fileName,callback) => {
//   // storage.refFromURL(`gs://${path}`)

//   console.log(await storage.refFromURL(`gs://kp-assist/viewer/viewer_jsons/12.json`).getMetadata());
//   console.log(await storage.refFromURL(`gs://${path}`).getMetadata());
//   await storage.refFromURL(`gs://${path}`).updateMetadata({metageneration:"2",cacheControl: 'public','firebaseStorageDownloadTokens': ''}).then(async () => {
//     console.log(await storage.refFromURL(`gs://${path}`).getMetadata());  
//   callback()
//   }).catch((error)=> {
//     console.log("Error while making file public",path,error);
//   })
// }