import { firestoreDB,firebaseConfig } from "./firebase";
// import { getFirestoreDoc } from "../components/util/firebase-firestore";
import { doc,setDoc } from "firebase/firestore";

let projectId = firebaseConfig["projectId"]

let inventoryBucket = projectId+"-inventory-bucket"
let summaryBucket = projectId+"-summary-bucket"
let outputBucket = projectId+"-chatapp"


let collectiondata = {
    "paths" : {
        "assist-paths" : {
            "summary-bucket": summaryBucket,
            "master-path": "gs://{masterBucket}/Master/LATEST/index.pkl",
            "master-bucket": outputBucket,
            "master-df-path": "gs://{masterBucket}/Master/LATEST/master.pkl",
            "output-bucket-path": "outputs/{name}/{device}/{file}",
            "vendor-file-path": "gs://{summaryBucket}/{uid}/{date}/Vendor_files/{vendorName}/{fileName}",
            "viewer-file-path": "{outputBucket}/viewer/viewer_jsons/",
            "output-bucket": outputBucket,
            "inventory-bucket": inventoryBucket,
            "error-bucket": outputBucket,
            "error-path": "gs://{errorBucket}/errors/errorData.json",
            "viewer-path": "https://storage.googleapis.com/{outputBucket}/viewer/index.html?res_id={resId}",
            "vendor-path": "gs://{masterBucket}/Latest/{uid}/vendorFile.xlsx",
            "user-file-path": "gs://{summaryBucket}/{uid}/{date}/User_files/{fileName}",
            "user-path": "gs://{masterBucket}/Latest/{uid}/userFile.xlsx",
            "compare-file-path": "https://storage.googleapis.com/{outputBucket}/compare-files/index.html?d={fileName}"
        },
        "assist-functions" : {
            "base_api": "https://us-central1-"+projectId+".cloudfunctions.net",
            "getAvailableName": "{base_api}/getAvailableName",
            "convertFile": "{base_api}/convertFile",
            "getDates": "{base_api}/GetDates",
        }
    }
}


export async function setFirestoreCollections()
{
    for(let collectionName in collectiondata)
    {
        let data = collectiondata[collectionName]
        for(let docName in data)
        {
            let docData = data[docName]
            await setDoc(doc(firestoreDB,collectionName,docName),docData) 
        }
    }
}



export async function setConfig()
{
    await setFirestoreCollections()
}