import React, { useState,Component } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { connect } from "react-redux";
import { NotificationContainer } from "react-notifications";
import { AppContainer } from "./AppChildren/AppContainer";
import { NoLoginReq } from "./AppChildren/NoLoginReq";
import { sendMessage } from "../util/util";
import {
  changeAuthState,
  addInventoryFiles,
  addSummaryFiles,
  addMasterFiles,
} from "../../actions";
// import { postQuery } from "../util/firebase-firestore";
import { getUserFiles, updateDocument, allDocuments,getFirestoreDoc } from "../util/firebase-firestore";
import createNotification from "../util/Notification";
import { postMessage } from "../../actions";
import { checkDocuments } from "../util/firebase-firestore";
import { loadMessages } from "../util/firebase-firestore";
import { auth } from "../util/firebase-authentication";
import LoadingSpinner from "../util/LoadingSpinner";
import "../../assets/css/App.css";
import { firestoreDB,domain } from "../../config/firebase";
import { addFireStoreDoc } from "../util/firebase-firestore";
// import { CookiesProvider, useCookies } from 'react-cookie';
library.add(fab);
/**
 * This is where the fetching of the user files is done
 * This is also where we load the messages for the user
 *
 */
class App extends Component {
  state = {
    isLoading: true,
    fileType: "userFileMessages",
  };


  setFileType = (newFileType) =>{
    this.setState({fileType: newFileType});
    this.props.postMessage('',true);
    checkDocuments(
      `chats/${this.props.user.uid}/${newFileType}/`, 
      "text", 
      "welcome",
      () => {        
        loadMessages(
          `chats/${this.props.user.uid}/${newFileType}/`,
          this.props.postMessage
        );
      },
      () => {
        sendMessage(this.props.user.uid,false,"welcome","desktop",newFileType);
      })
  }

  setFileDate = (newDate) => {
    //function to set new date of uploaded source file which will be used to fetch result of query
    this.setState({fileDate: newDate});
    
  }

  //This function is for fetching the files in the background - summary master and inventory
  //Currently it has no use as the project does not have the option to show inventory and analysis
  // To show those uncomment this.fetchUserFiles() in the componentDidMount
  // fetchUserFiles = () => {
  //   getUserFiles(
  //     this.props.user.uid,
  //     "inventoryFiles",
  //     this.props.addInventoryFiles
  //   );
  //   getUserFiles(
  //     this.props.user.uid,
  //     "summaryFiles",
  //     this.props.addSummaryFiles,
  //     (files = []) => {
  //       files.forEach((file) => {
  //         if (file && !file.ack) {
  //           createNotification("Summary File is available !!", "success");
  //           postQuery(`chats/${this.props.user.uid}/userFileMessages`, {
  //             botReply: true,
  //             fileAck: true,
  //             text: "Latest summary file is now available.",
  //             downloadURL: file.downloadURL,
  //             timestamp: Date.now(),
  //           });

  //           updateDocument(
  //             `userFiles/${this.props.user.uid}/summaryFiles`,
  //             file.docID,
  //             {
  //               ack: true,
  //             }
  //           );
  //         }
  //       });
  //     }
  //   );
  //   getUserFiles(this.props.user.uid, "masterFiles", this.props.addMasterFiles);
  // };
  //This gets called when the component is created
  /**
   * The function to load messages and files when the App component is rendered
   */
  componentDidMount = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        getFirestoreDoc("accessPermissions",domain,(whiteListedMails)=>{
          let currUserMail = auth.currentUser.email
          whiteListedMails = whiteListedMails["accounts"]
          if(!whiteListedMails.includes(currUserMail))
          {
            auth
            .signOut()
            .then((response) => {
              this.props.changeAuthState(null)
              window.alert("Unauthorized mail..Please Contact at kalpesh@d360.tech");
              window.location.reload();
            })
            .catch((err) => {
              console.error(err);
            });
            this.setState({
              isLoading: false,
            });
          }
          else 
          {
            // console.log("user detected");
            this.props.changeAuthState(user);
            getFirestoreDoc("chats",auth.currentUser.uid,(data)=>{
              if(!data){
                addFireStoreDoc('chats', auth.currentUser.uid, {
                    lastEdit: new Date(),
                    name :  auth.currentUser.displayName,
                    photoUrl : auth.currentUser.photoURL,
                    uid : auth.currentUser.uid,
                    email: auth.currentUser.email
                });
            }
            else{
                updateDocument('chats', auth.currentUser.uid, {
                    lastEdit: new Date(),
                    name :  auth.currentUser.displayName,
                    photoUrl : auth.currentUser.photoURL,
                    uid : auth.currentUser.uid,
                    email : auth.currentUser.email
                });
            }
            },null,()=>{
                addFireStoreDoc('chats', auth.currentUser.uid, {
                  lastEdit: new Date(),
                  name :  auth.currentUser.displayName,
                  photoUrl : auth.currentUser.photoURL,
                  uid : auth.currentUser.uid,
                  email: auth.currentUser.email
              });
            })
            checkDocuments(
              `chats/${this.props.user.uid}/${this.state.fileType}/`, 
              "text", 
              "welcome",
              () => {
                loadMessages(
                  `chats/${this.props.user.uid}/${this.state.fileType}/`,
                  this.props.postMessage
                );
              },
              () => {
                sendMessage(this.props.user.uid,false,"welcome","desktop",this.state.fileType);
              })
              this.setState({
                isLoading: false,
              });
          }

        },null,(err)=>{
          // console.log("error in whitemail : ",err);
            auth
            .signOut()
            .then((response) => {
              this.props.changeAuthState(null)
              window.alert("Unauthorized mail..Please Contact at kalpesh@d360.tech");
              window.location.reload();
            })
            .catch((err) => {
              console.error(err);
            });
            this.setState({
              isLoading: false,
            });
        })
        // this.fetchUserFiles();
        // loadMessages(
        //   `chats/${this.props.user.uid}/${this.state.fileType}`,
        //   // `chats/${this.props.user.uid}/messages/${this.state.fileType}`,
        //   this.props.postMessage
        // );
        
      }
      else 
      {
        this.setState({
          isLoading: false,
        });
      }
      
    });
  };


  // componentDidUpdate = () => {
  //   checkDocuments(
  //     `chats/${this.props.user.uid}/${this.state.fileType}/`, 
  //     "text", 
  //     "welcome",
  //     () => {
  //       loadMessages(
  //         `chats/${this.props.user.uid}/${this.state.fileType}/`,
  //         this.props.postMessage
  //       );
  //     },
  //     () => {
  //       sendMessage(this.props.user.uid,false,"welcome","desktop",this.state.fileType);
  //     })
  // }


  
  renderApp = () => {
    if (this.state.isLoading) {
      return (
        <div
          className="container d-flex justify-content-center div-top-pad">
          <LoadingSpinner />
        </div>
      );
    }

    if (this.props.user) {
     return <AppContainer  fileType={this.state.fileType} setFileType={this.setFileType} />;
    }
    return <NoLoginReq />;
  };
  /**
   *
   * @returns The App tag itself, inside which, the whole of our project logic resides
   */
  render = () => {


    
    let userData = {
      "U1": {
        "1 to 10": {
          "userFileStatus": { "status": "working fine", "status_code": 200 },
          "vendorFileStatus": { "status": "working fine", "status_code": 200 },
          "masterFileStatus": { "status": "not working", "status_code": 500 }
        }, "dd rd": {
          "userFileStatus": { "status": "working fine", "status_code": 200 },
          "vendorFileStatus": { "status": "working fine", "status_code": 200 },
          "masterFileStatus": { "status": "not working", "status_code": 500 }
        }, "rd":
        {
          "userFileStatus": { "status": "working fine", "status_code": 200 },
          "vendorFileStatus": { "status": "working fine", "status_code": 200 },
          "masterFileStatus": { "status": "working fine", "status_code": 200 }
        }, "rrd":
        {
          "userFileStatus": { "status": "working fine", "status_code": 200 },
          "vendorFileStatus": { "status": "working fine", "status_code": 500 },
          "masterFileStatus": { "status": "working fine", "status_code": 500 }
        }
      },
      "U2": {
        "1 to 10": {
          "userFileStatus": { "status": "working fine", "status_code": 200 },
          "vendorFileStatus": { "status": "working fine", "status_code": 200 },
          "masterFileStatus": { "status": "not working", "status_code": 500 }
        }, "dd rd": {
          "userFileStatus": { "status": "working fine", "status_code": 200 },
          "vendorFileStatus": { "status": "working fine", "status_code": 200 },
          "masterFileStatus": { "status": "not working", "status_code": 500 }
        }, "rd":
        {
          "userFileStatus": { "status": "working fine", "status_code": 200 },
          "vendorFileStatus": { "status": "working fine", "status_code": 200 },
          "masterFileStatus": { "status": "working fine", "status_code": 200 }
        }, "rrd":
        {
          "userFileStatus": { "status": "working fine", "status_code": 200 },
          "vendorFileStatus": { "status": "working fine", "status_code": 200 },
          "masterFileStatus": { "status": "working fine", "status_code": 200 }
        }
      },
    }
    // let userData = this.getData();
    return (
      <div className="rootDiv">
        <NotificationContainer />
        {this.renderApp()}
        {/* <UserStatus userData={tempdata}></UserStatus> */}
        {/* {(userData) ?
          (
            <UserStatus docID={new URLSearchParams(window.location.search).get("docID")} userData={userData}></UserStatus>
          )
          :
          (
            <div>
              Doc ID not passed
            </div>
          )} */}

      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  changeAuthState,
  addInventoryFiles,
  addMasterFiles,
  addSummaryFiles,
  postMessage,
})(App);
